import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AdsArea = ({
	adPosition,
	additionalClass = "",
	areaWidth = "100%", // 100%
	areaHeight = "100%", // 30vh
}) => {
	const styles = {
		width: areaWidth,
		height: areaHeight,
		backgroundColor: "#f5f5f5",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "14px",
		fontWeight: "bold",
	};

	if (adPosition) {
		styles.position = "fixed";
		styles[adPosition] = "3px";
	}

	const [user, setUser] = useState(null);

	// const [adFailedToLoad, setAdFailedToLoad] = useState(false);

	// useEffect(() => {
	// 	const pushAd = () => {
	// 		try {
	// 			const adsbygoogle = window.adsbygoogle;
	// 			// console.log({ adsbygoogle });
	// 			adsbygoogle.push({});
	// 		} catch (e) {
	// 			console.error(e);
	// 		}
	// 	};

	// 	let interval = setInterval(() => {
	// 		// Check if Adsense script is loaded every 300ms
	// 		if (window.adsbygoogle) {
	// 			pushAd();
	// 			// clear the interval once the ad is pushed so that function isn't called indefinitely
	// 			clearInterval(interval);
	// 		}
	// 	}, 300);

	// 	// Check if the ad was blocked
	// 	if (window.adsbygoogle.push.length === 0) {
	// 		setAdFailedToLoad(true);
	// 		// console.log("Pourquoi tu bloques les pubs ? 😪");
	// 	}

	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// }, []);

	// recuperer les informations de l'utilisateur depuis le jwt
	useEffect(() => {
		const jwtData = localStorage.getItem("jwt");
		if (jwtData) {
			const decodedJWT = jwtDecode(jwtData);
			if (decodedJWT) {
				// Vérifiez si id_user est présent dans decodedJWT
				setUser(decodedJWT);
			}
			// console.log("decodedJWT", decodedJWT);
		}
	}, []);

	if (
		(user && user.accountType === "admin") ||
		(user && user.accountType === "pro")
	) {
		return null;
	} else {
		return (
			<>
				{/* <div style={styles} className={"rounded-3 " + additionalClass}> */}
					{/* {adFailedToLoad === true ? ( */}
						{/* <span>
							<h2 className='fw-bold'> Dealo Pub </h2>
						</span> */}
					{/* ) : ( */}
						{/* <div
							id='ad-container'
							style={{ width: "100%" }}
							className={"rounded-3 " + additionalClass}
						>
							<ins
								className='adsbygoogle'
								style={{ display: "block", width: "100%" }}
								data-full-width-responsive='true'
								data-ad-client="ca-pub-8069854624054402"
								data-ad-slot="5373843424"
								data-ad-format='auto'
							></ins>
						</div> */}
					{/* )} */}
				{/* </div> */}
			</>
		);
	}
};

export default AdsArea;
