import React, { useState, useEffect } from "react";
import logo from "../dealo/dealo-logo.png";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Avatar } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const AdminHeader = () => {
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	const [role, setRole] = useState(null);

	// recuperer les informations de l'utilisateur depuis le jwt
	useEffect(() => {
		const jwtData = localStorage.getItem("jwt");
		if (jwtData) {
			const decodedJWT = jwtDecode(jwtData);
			setUser(decodedJWT);
			setRole(decodedJWT.accountType);
		} else {
			navigate("/"); // Redirigez si aucun JWT n'est présent
		}
	}, [navigate]);

	// console.log(role);

	useEffect(() => {
		if (role) {
			if (role !== "admin") {
				navigate("/"); // Redirigez si l'utilisateur n'est pas un administrateur
			}
		}
	}, [navigate, user]);

	return (
		<div className='vertical-menu'>
			<div data-simplebar className='h-100'>
				<div id='sidebar-menu'>
					<ul className='metismenu list-unstyled' id='side-menu'>
						<li>
							{/* Logo */}
							<a href='/admin' className='waves-effect'>
								<img
									src={logo}
									alt='Dealo'
									height='25'
									style={{ cursor: "pointer" }}
								/>
							</a>

							{/* Modération des comptes */}
							<Tooltip title='Modération des comptes' arrow placement='right'>
								<a href='/admin/user' className='waves-effect'>
									<i className='fas fa-user-circle'></i>
								</a>
							</Tooltip>

							{/* Modération des annonces */}
							<Tooltip title='Modération des annonces' arrow placement='right'>
								<a href='/admin/posts' className='waves-effect'>
									<i className='fas fa-ad'></i>
								</a>
							</Tooltip>

							{/* Modération des signalements */}
							<Tooltip
								title='Modération des signalements'
								arrow
								placement='right'
							>
								<a href='/admin/reports' className='waves-effect'>
									<i className='fas fa-exclamation-triangle'></i>
								</a>
							</Tooltip>

							{/* Modération des évaluations utilisateurs */}
							<Tooltip
								title='Modération des évaluations utilisateurs'
								arrow
								placement='right'
							>
								<a href='/admin/ratings' className='waves-effect'>
									<i className='fas fa-star'></i>
								</a>
							</Tooltip>
							{/* Autres éléments de sous-menu */}
						</li>

						<li
							style={{
								position: "absolute",
								bottom: 6,
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<div className='image mr-3'>
								<Tooltip
									title={user && user.username ? user.username : "Utilisateur"}
									arrow
								>
									<Avatar
										src={user && user.profilePicture ? user.profilePicture : ""}
										alt=""
										sx={{
											width: 30,
											height: 30,
											border: "2px solid light",
										}}
									/>
								</Tooltip>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default AdminHeader;
