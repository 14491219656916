import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";

import { pageTransition, pageTransform } from "../../components";

const Subheader = ({ loader, category }) => {
	const categoryIcons = {
		Immobilier: "fas fa-home",
		Automobiles: "fas fa-car",
		Emploi: "fas fa-briefcase",
		Services: "fas fa-concierge-bell",
		"High-Tech": "fas fa-laptop",
		"Mode et accessoires": "fas fa-tshirt",
		"Maison et jardin": "fas fa-seedling",
		Animaux: "fas fa-paw",
		"Loisirs et divertissements": "fas fa-football-ball",
	};

	const matches = useMediaQuery("(max-width:992px)");

	// State for storing anchors
	const [anchors, setAnchors] = useState([]);

	// Function to handle click
	const handleClick = (id) => (event) => {
		setAnchors((prev) => ({ ...prev, [id]: event.currentTarget }));
	};

	// Function to handle close
	const handleClose = (id) => () => {
		setAnchors((prev) => ({ ...prev, [id]: null }));
	};

	return (
		<>
			{loader && (
				<motion.div
					className='text-center app-flex'
					initial='initial'
					animate='in'
					exit='out'
					variants={pageTransform}
					transition={pageTransition}
					style={{ whiteSpace: "nowrap" }}
				>
					{category.map((category, index, array) => (
						<div key={category.id}>
							<Button
								id={`dropdown${category.id}`}
								aria-controls={`simple-menu${category.id}`}
								aria-haspopup='true'
								onClick={handleClick(category.id)}
								className='text-secondary shadow-sm rounded-3 py-1 m-1'
								sx={{
									fontSize: {
										xs: "11px",
										sm: "12px",
										md: "10.7px",
										lg: "14px",
										xl: "14px",
									},
									minWidth: "max-content",
									gap: "5px",
									color: "black",
									textTransform: "none",
								}}
							>
								<i
									className={categoryIcons[category.name_category]}
									style={{ fontSize: "10px" }}
								></i>{" "}
								{category.name_category}
								{/* {index !== array.length - 1 && (
									<i
										className='fas fa-circle'
										style={{ fontSize: "2.5px" }}
									></i>
								)} */}
							</Button>
							<Menu
								id={`simple-menu${category.id}`}
								anchorEl={anchors[category.id]}
								keepMounted
								open={Boolean(anchors[category.id])}
								onClose={handleClose(category.id)}
							>
								<MenuItem
									className='text-secondary'
									key={category.id}
									onClick={() =>
										(window.location.href = `/filter?category=${category.id}`)
									}
									style={{
										fontSize: "14px",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<b className='d-flex align-items-center justify-content-center text-center'>
										Voir tout&nbsp;
										<i className='fas fa-arrow-alt-circle-right'></i>
									</b>
								</MenuItem>
								{category.subcategories.map((subcategory) => (
									<MenuItem
										className='text-secondary'
										key={subcategory.id}
										onClick={() =>
											(window.location.href = `/filter?category=${category.id}&subcategory=${subcategory.id}`)
										}
										style={{
											fontSize: "14px",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										{subcategory.name_subcategory}
									</MenuItem>
								))}
							</Menu>
						</div>
					))}
				</motion.div>
			)}
		</>
	);
};

export default Subheader;
