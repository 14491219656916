import React from "react";
import { Header, Footer, AdminHeader, AdminFooter } from "./components";

const Layout = ({
	children,
	childrenAdmin,
	showHeader = true,
	showFooter = true,
	showAdminHeader = false,
	showAdminFooter = false,
}) => (
	<>
		{/* User Layout */}
		{showHeader && !showAdminHeader && <Header />}
		{children}
		{showFooter && !showAdminFooter && <Footer />}

		{/* Admin Layout */}
		{showAdminHeader && (
			<div style={{ display: "flex" }}>
				{/* Barre latérale */}
				<AdminHeader />

				{/* Contenu principal */}
				<div>
					<div
						style={{
							margin: "35px 35px 45px 90px",
						}} /* margin: [top] [right] [bottom] [left] */
					>
						{childrenAdmin}
					</div>
					<div style={{ marginLeft: "65px" }}>
						{showAdminFooter && <AdminFooter />}
					</div>
				</div>
			</div>
		)}
	</>
);

export default Layout;
