export const pageTransition = {
	type: "tween",
	transition: "anticipate",
	duration: 0.3,
};

const PageTransition = () => {
	return pageTransition;
};

export default PageTransition;
