import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TuneIcon from "@mui/icons-material/TuneRounded";
import "./FilterCanvas.css";
import { motion } from "framer-motion";
import { enqueueSnackbar } from "notistack";

export default function FilterCanvas() {
	// style
	const fadeInUpVariant = {
		opacity: 0,
		y: 20,
		transition: { duration: 0.3, ease: "easeInOut" },
	};
	const visibleVariant = {
		opacity: 1,
		y: 0,
		transition: { duration: 0.3, ease: "easeInOut" },
	};
	const fadeOutDownVariant = {
		opacity: 0,
		y: -20,
		transition: { duration: 0.3, ease: "easeInOut" },
	};

	// Recuperer les informations depuis la base de données
	const [fetchedRegion, setFetchedRegion] = useState([]);
	const [fetchedCity, setFetchedCity] = useState([]);
	const [fetchedDepartment, setFetchedDepartment] = useState([]);
	const [fetchedCategory, setFetchedCategory] = useState([]);
	const [fetchedSubCategory, setFetchedSubCategory] = useState([]);
	const [fetchedBrand, setFetchedBrand] = useState([]);
	const [fetchedAutoinfo, setFetchedAutoInfo] = useState([]);

	// Get search parameters from URL
	const [searchParams, setSearchParams] = useSearchParams();
	const [region, setRegion] = useState(searchParams.get("region") || "");
	const [city, setCity] = useState(searchParams.get("city") || "");
	const [department, setDepartment] = useState(
		searchParams.get("department") || ""
	);

	const [category, setCategory] = useState(searchParams.get("category") || "");
	const [subCategory, setSubCategory] = useState(
		searchParams.get("subcategory") || ""
	);
	const [brand, setBrand] = useState(searchParams.get("brand") || "");
	const [minPrice, setMinPrice] = useState(searchParams.get("priceMin") || "");
	const [maxPrice, setMaxPrice] = useState(searchParams.get("priceMax") || "");
	const [autoYearMin, setAutoYearMin] = useState(
		searchParams.get("autoYearMin") || ""
	);
	const [autoYearMax, setAutoYearMax] = useState(
		searchParams.get("autoYearMax") || ""
	);
	const [autoTransmission, setAutoTransmission] = useState(
		searchParams.get("autoTransmission") || ""
	);
	const [autoFueltype, setAutoFueltype] = useState(
		searchParams.get("autoFueltype") || ""
	);
	const [autoMileageMin, setAutoMileageMin] = useState(
		searchParams.get("autoMileageMin") || ""
	);
	const [autoMileageMax, setAutoMileageMax] = useState(
		searchParams.get("autoMileageMax") || ""
	);

	// recuperer les regions
	const fetchRegions = async () => {
		try {
			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/get/region`
			);
			const data = await response.json();
			setFetchedRegion(data);
		} catch (error) {
			console.error("Error fetching regions:", error);
		}
	};

	// recuperer les villes
	const fetchCities = async (id_region) => {
		try {
			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/get/cityByRegion/${id_region}`
			);
			const data = await response.json();
			setFetchedCity(data);
		} catch (error) {
			console.error("Error fetching cities:", error);
		}
	};

	// recuperer les departements
	const fetchDepartments = async (id_city) => {
		try {
			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/get/departmentBycity/${id_city}`
			);
			const data = await response.json();
			setFetchedDepartment(data);
		} catch (error) {
			console.error("Error fetching departments:", error);
		}
	};

	// recuperer les infos de localisation
	useEffect(() => {
		fetchRegions();

		if (region) {
			fetchCities(region);
		}

		if (city) {
			fetchDepartments(city);
		}
	}, [region, city]);

	// Recuperer les categories
	const fetchCategories = async () => {
		try {
			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/get/category`
			);
			const data = await response.json();
			setFetchedCategory(data);
		} catch (error) {
			console.error("Error fetching categories:", error);
		}
	};

	// // Recuperer les sous-categories
	const fetchSubCategories = async (id_category) => {
		try {
			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/get/subcategory/${id_category}`
			);
			const data = await response.json();
			setFetchedSubCategory(data);
		} catch (error) {
			console.error("Error fetching subcategories:", error);
		}
	};

	// // Recuperer les marques basées sur les sous-categories
	const fetchBrands = async (id_subcategory) => {
		try {
			if (["6", "7", "9", "21", "22", "23"].includes(subCategory)) {
				const response = await fetch(
					`${window.config.REACT_APP_BACKEND_URL}/api/get/brand/${id_subcategory}`
				);
				const data = await response.json();
				setFetchedBrand(data);
			}
		} catch (error) {
			console.error("Error fetching brands:", error);
		}
	};

	// recuperer les infos de specificités
	useEffect(() => {
		fetchCategories();

		if (category) {
			fetchSubCategories(category);
		}

		if (subCategory) {
			fetchBrands(subCategory);
		}
	}, [category, subCategory]);

	// recuperer les autodetails Uniquement récupérer les autodetails pour les sous-catégories suivantes :  Voitures - 6, Motos - 7, Camions - 9
	const fetchAutoDetails = async () => {
		try {
			if (["6", "7", "9"].includes(subCategory)) {
				const response = await fetch(
					`${window.config.REACT_APP_BACKEND_URL}/api/get/autodetails`
				);
				const data = await response.json();
				setFetchedAutoInfo(data);
			}
		} catch (error) {
			console.error("Error fetching auto details:", error);
		}
	};

	// recuperer les infos de voitures
	useEffect(() => {
		if (subCategory) {
			fetchAutoDetails();
		}
	}, [subCategory]);

	// verifier si les champs obligatoires sont remplis
	const areRequiredFieldsFilled = () => {
		const requiredFields = [];

		if (autoYearMin) {
			requiredFields.push(autoYearMax);
		}

		if (autoMileageMin) {
			requiredFields.push(autoMileageMax);
		}

		if (minPrice) {
			requiredFields.push(maxPrice);
		}

		return requiredFields.every((field) => field !== null && field !== "");
	};

	// Appliquer les filtres
	const applyFilters = () => {
		// si les champs obligatoires ne sont pas remplis
		if (!areRequiredFieldsFilled()) {
			enqueueSnackbar("Veuillez remplir les champs maximum correspondants", {
				variant: "error",
			});
			return;
		}

		//On recupere la valeures dess champs et on l'insere dans l'url
		setSearchParams((params) => {
			const newParams = new URLSearchParams(params);
			// Mettre à jour les paramètres de filtre
			region ? newParams.set("region", region) : newParams.delete("region");
			city ? newParams.set("city", city) : newParams.delete("city");
			department
				? newParams.set("department", department)
				: newParams.delete("department");
			category
				? newParams.set("category", category)
				: newParams.delete("category");
			subCategory
				? newParams.set("subcategory", subCategory)
				: newParams.delete("subcategory");
			brand ? newParams.set("brand", brand) : newParams.delete("brand");
			minPrice
				? newParams.set("priceMin", minPrice)
				: newParams.delete("priceMin");
			maxPrice
				? newParams.set("priceMax", maxPrice)
				: newParams.delete("priceMax");
			autoYearMin
				? newParams.set("autoYearMin", autoYearMin)
				: newParams.delete("autoYearMin");
			autoYearMax
				? newParams.set("autoYearMax", autoYearMax)
				: newParams.delete("autoYearMax");
			autoFueltype
				? newParams.set("autoFueltype", autoFueltype)
				: newParams.delete("autoFueltype");
			autoTransmission
				? newParams.set("autoTransmission", autoTransmission)
				: newParams.delete("autoTransmission");
			autoMileageMin
				? newParams.set("autoMileageMin", autoMileageMin)
				: newParams.delete("autoMileageMin");
			autoMileageMax
				? newParams.set("autoMileageMax", autoMileageMax)
				: newParams.delete("autoMileageMax");

			return newParams;
		});

		// Reload the page
		window.location.reload();
	};

	// Reinitialiser les filtres
	const resetFilters = () => {
		window.location.href = "/filter";
	};

	return (
		<React.Fragment>
			{/* BUTTON */}
			<button
				className='custom_btn btn-sm'
				data-bs-toggle='offcanvas'
				href='#filterOffCanvas'
				role='button'
				aria-controls='filterOffCanvas'
			>
				<TuneIcon
					sx={{
						fontSize: "20px",
						marginBottom: "1px",
					}}
				/>
				&nbsp;Filtres
			</button>

			<div
				className='offcanvas custom_offcanvas offcanvas-end'
				id='filterOffCanvas'
				tabIndex='-1'
				aria-labelledby='filterOffCanvasLabel'
				data-bs-backdrop='true'
			>
				{/* TITRE */}
				<div className='offcanvas-header'>
					<h5 className='offcanvas-title' id='filterOffCanvasLabel'>
						<TuneIcon
							sx={{
								fontSize: "20px",
								marginBottom: "1px",
							}}
						/>
						&nbsp;Tous les filtres
					</h5>
					<button
						type='button'
						className='btn-close text-reset'
						data-bs-dismiss='offcanvas'
						aria-label='Close'
					></button>
				</div>
				<div
					className='offcanvas-body'
					style={{ gap: "1vh", display: "flex", flexDirection: "column" }}
				>
					{/* LOCALISATION */}
					<div
						className='filter-group-wrapper app-flex form-group custom_form-group'
						data-info='📌 Localisation'
					>
						{/* REGION  */}
						<div className='form-group custom_form-group app-flex'>
							<label htmlFor='Region'>Région</label>
							<select
								className='custom_select app-flex'
								id='Region'
								value={region ? region : ""}
								onChange={(e) => {
									setRegion(e.target.value);
									setCity("");
									setDepartment("");
								}}
							>
								<option value=''>Choisir une région</option>
								{fetchedRegion.map((fetchedRegion) => (
									<option key={fetchedRegion.id} value={fetchedRegion.id}>
										{fetchedRegion.name_region}
									</option>
								))}
							</select>
						</div>

						{/* VILLES */}
						{region != "" && (
							<motion.div
								animate={visibleVariant}
								initial={fadeInUpVariant}
								exit={fadeOutDownVariant}
								className='form-group custom_form-group app-flex'
							>
								<label htmlFor='Ville'>Ville</label>
								<select
									className='custom_select app-flex'
									id='Ville'
									value={city ? city : ""}
									onChange={(e) => {
										setCity(e.target.value);
										setDepartment("");
									}}
								>
									<option value=''>Choisir une ville</option>
									{fetchedCity.map((fetchedCity) => (
										<option key={fetchedCity.id} value={fetchedCity.id}>
											{fetchedCity.name_city}
										</option>
									))}
								</select>
							</motion.div>
						)}

						{/* COMMUNE */}
						{region != "" && city != "" && (
							<motion.div
								animate={visibleVariant}
								initial={fadeInUpVariant}
								exit={fadeOutDownVariant}
								className='form-group custom_form-group app-flex'
							>
								<label htmlFor='Commune'>Communes</label>
								<select
									className='custom_select app-flex'
									id='Commune'
									value={department ? department : ""}
									onChange={(e) => {
										setDepartment(e.target.value);
									}}
								>
									<option value=''>Choisir une commune</option>
									{fetchedDepartment.map((fetchedDepartment) => (
										<option
											key={fetchedDepartment.id}
											value={fetchedDepartment.id}
										>
											{fetchedDepartment.name_department}
										</option>
									))}
								</select>
							</motion.div>
						)}
					</div>

					{/* SPECIFICITES */}
					<div
						className='filter-group-wrapper app-flex form-group custom_form-group'
						data-info='🔍 Précisions'
					>
						{/* CATEGORIE */}
						<div className='form-group custom_form-group app-flex'>
							<label className='col-form-label'>Catégorie</label>
							<select
								className='custom_select app-flex'
								id='id_category'
								required
								value={category ? category : ""}
								onChange={(e) => {
									setCategory(e.target.value);
									setSubCategory("");
									setBrand("");
									setAutoYearMin("");
									setAutoYearMax("");
									setAutoTransmission("");
									setAutoFueltype("");
									setAutoMileageMin("");
									setAutoMileageMax("");
								}}
							>
								<option value=''>Choisir une catégorie</option>
								{fetchedCategory.map((fetchedcategories) => (
									<option
										key={fetchedcategories.id}
										value={fetchedcategories.id}
									>
										{fetchedcategories.name_category}
									</option>
								))}
								fetchedcategories
							</select>
						</div>

						{/* SOUS-CATEGORIE */}
						{category !== "" && (
							<motion.div
								animate={visibleVariant}
								initial={fadeInUpVariant}
								exit={fadeOutDownVariant}
								className='form-group custom_form-group app-flex'
							>
								<label className='col-form-label d-inline-block'>
									Sous-Categorie
								</label>
								<select
									className='custom_select app-flex'
									value={subCategory ? subCategory : ""}
									onChange={(e) => {
										setSubCategory(e.target.value);
										setBrand("");
										setAutoYearMin("");
										setAutoYearMax("");
										setAutoTransmission("");
										setAutoFueltype("");
										setAutoMileageMin("");
										setAutoMileageMax("");
									}}
									required
									id='id_subcategory'
								>
									<option value=''>Choisir une sous-catégorie</option>
									{fetchedSubCategory.map((fecthedsubCategories) => (
										<option
											key={fecthedsubCategories.id}
											value={fecthedsubCategories.id}
										>
											{fecthedsubCategories.name_subcategory}
										</option>
									))}
								</select>
							</motion.div>
						)}

						{/* MARQUES */}
						{category !== "" &&
							subCategory !== "" &&
							["6", "7", "9", "21", "22", "23"].includes(subCategory) && (
								<motion.div
									animate={visibleVariant}
									initial={fadeInUpVariant}
									exit={fadeOutDownVariant}
									className='form-group custom_form-group app-flex'
								>
									<label className='col-form-label'>Marque</label>
									<select
										className='custom_select app-flex'
										id='id_brand'
										value={brand ? brand : ""}
										onChange={(e) => {
											setBrand(e.target.value);
											setAutoYearMin("");
											setAutoYearMax("");
											setAutoTransmission("");
											setAutoFueltype("");
											setAutoMileageMin("");
											setAutoMileageMax("");
										}}
										required
									>
										<option value=''>Choisir une marque</option>
										{fetchedBrand.map((fetchedbrand) => (
											<option key={fetchedbrand.id} value={fetchedbrand.id}>
												{fetchedbrand.brandname}
											</option>
										))}
									</select>
								</motion.div>
							)}
					</div>

					{/* VOITURES */}
					{category !== "" &&
						subCategory !== "" &&
						brand !== "" &&
						["6", "7", "9"].includes(subCategory) && (
							<div
								className='filter-group-wrapper app-flex form-group custom_form-group'
								data-info='🚗 Véhicule'
							>
								<motion.div
									animate={visibleVariant}
									initial={fadeInUpVariant}
									exit={fadeOutDownVariant}
									className='form-group custom_form-group app-flex'
								>
									<label className='col-form-label'>Détails</label>
									<div
										className='form-group custom_form-group app-flex'
										style={{
											flexDirection: "row",
											gap: "5px",
											justifyContent: "space-between",
										}}
									>
										{/* AUTO YEAR */}
										<div
											className='form-group custom_form-group app-flex'
											style={{ flexGrow: "1" }}
										>
											<label className='col-form-label'>Année Min</label>
											<input
												type='number'
												className='form-control custom_select'
												min='1990'
												placeholder='Année de mise en circulation'
												max={
													autoYearMax ? autoYearMax : new Date().getFullYear()
												}
												value={autoYearMin ? autoYearMin : ""}
												onChange={(e) => {
													setAutoYearMin(e.target.value);
												}}
											/>
										</div>

										<div
											className='form-group custom_form-group app-flex'
											style={{ flexGrow: "1" }}
										>
											<label className='col-form-label'>Année Max</label>
											<input
												type='number'
												className='form-control custom_select'
												min={autoYearMin ? autoYearMin : "1990"}
												placeholder='Année de mise en circulation'
												max={new Date().getFullYear()}
												value={autoYearMax ? autoYearMax : ""}
												onChange={(e) => {
													setAutoYearMax(e.target.value);
												}}
											/>
										</div>

										{/* AUTO TRANSMISSION */}

										<div
											className='form-group custom_form-group app-flex'
											style={{ flexGrow: "1" }}
										>
											<label className='col-form-label'>Transmission</label>
											<select
												className='form-control custom_select'
												value={autoTransmission ? autoTransmission : ""}
												onChange={(e) => {
													setAutoTransmission(e.target.value);
												}}
											>
												<option value=''>Choisir une transmission</option>
												{fetchedAutoinfo
													.filter((item) => item.id === 1 || item.id === 2)
													.map((item) => (
														<option key={item.id} value={item.id}>
															{item.name}
														</option>
													))}
											</select>
										</div>
									</div>

									{/* AUTO FUELTYPE */}
									<div
										className='form-group custom_form-group app-flex'
										style={{
											flexDirection: "row",
											gap: "5px",
											justifyContent: "space-between",
										}}
									>
										<div
											className='form-group custom_form-group app-flex'
											style={{ flexGrow: "1" }}
										>
											<label className='col-form-label'>Motorisation</label>
											<select
												className='form-control custom_select'
												value={autoFueltype ? autoFueltype : ""}
												onChange={(e) => {
													setAutoFueltype(e.target.value);
												}}
											>
												<option value=''>Choisir un type de carburant</option>
												{fetchedAutoinfo
													.filter(
														(item) =>
															item.id === 3 || item.id === 4 || item.id === 5
													)
													.map((item) => (
														<option key={item.id} value={item.id}>
															{item.name}
														</option>
													))}
											</select>
										</div>

										{/* AUTO MILEAGE */}
										<div
											className='form-group custom_form-group app-flex'
											style={{ flexGrow: "1" }}
										>
											<label className='col-form-label'>Km Min</label>
											<input
												type='number'
												className='form-control custom_select'
												placeholder='Kilométrage'
												min={10}
												max={autoMileageMax ? autoMileageMax : "10"}
												value={autoMileageMin ? autoMileageMin : ""}
												onChange={(e) => {
													setAutoMileageMin(e.target.value);
												}}
											/>
										</div>

										<div
											className='form-group custom_form-group app-flex'
											style={{ flexGrow: "1" }}
										>
											<label className='col-form-label'>Km Max</label>
											<input
												type='number'
												className='form-control custom_select'
												placeholder='Kilométrage'
												min={autoMileageMin ? autoMileageMin : "10"}
												value={autoMileageMax ? autoMileageMax : ""}
												onChange={(e) => {
													setAutoMileageMax(e.target.value);
												}}
											/>
										</div>
									</div>
								</motion.div>
							</div>
						)}

					{/* PRIX */}
					<div
						className='filter-group-wrapper form-group custom_form-group'
						data-info='💰 Prix'
					>
						<label className='col-form-label'>Prix</label>
						<div className='form-group app-flex' style={{ gap: "10px" }}>
							<input
								className='custom_select app-flex'
								min={0}
								max={maxPrice ? maxPrice : ""}
								minLength={0}
								maxLength={23}
								type='number'
								placeholder='Prix Minimun'
								id='min'
								value={minPrice ? minPrice : ""}
								onChange={(e) => {
									setMinPrice(e.target.value);
								}}
							/>
							<input
								className='custom_select app-flex'
								min={minPrice ? minPrice : ""}
								minLength={0}
								maxLength={23}
								type='number'
								placeholder='Prix Maximum'
								id='max'
								value={maxPrice ? maxPrice : ""}
								onChange={(e) => {
									setMaxPrice(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
				{/* BUTTONS */}
				<div className='offcanvas-footer d-flex justify-content-around m-2'>
					<button
						className='btn btn-outline-secondary btn-lg'
						type='button'
						onClick={resetFilters}
					>
						Réinitialiser
					</button>
					<button
						className='btn btn-success btn-lg'
						onClick={applyFilters}
						type='button'
					>
						Appliquer
					</button>
				</div>
			</div>
		</React.Fragment>
	);
}
