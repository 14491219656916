import React, { useEffect, useState, useRef } from "react";

const LazyLoadItem = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.unobserve(entry.target);
				}
			});
		});

		observer.observe(ref.current);

		return () => observer.disconnect();
	}, []);

	return <div ref={ref}>{isVisible && children}</div>;
};

export default LazyLoadItem;
