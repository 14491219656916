import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./CarouselWrapper.css";

const THRESHOLD = 0.1;

const CarouselWrapper = ({
	children,
	sliderTitle,
	seeMoreLink,
	seeMoreText,
	shadowOnScroll,
	width = "90",
}) => {
	const sliderContainerRef = useRef(null);
	const [isLeftArrowHidden, setIsLeftArrowHidden] = useState(true); // Initial state
	const [isRightArrowHidden, setIsRightArrowHidden] = useState(false); // Initial state
	const [isShadowOnScroll, setShadowOnScroll] = useState(true);

	const handleScrollLeft = () => {
		const container = sliderContainerRef.current;
		if (container) {
			container.scrollTo({
				left: container.scrollLeft - 300, // Adjust scroll amount as needed
				behavior: "smooth",
			});
		}
	};

	const handleScrollRight = () => {
		const container = sliderContainerRef.current;
		if (container) {
			container.scrollTo({
				left: container.scrollLeft + 300, // Adjust scroll amount as needed
				behavior: "smooth",
			});
		}
	};

	const handleScroll = () => {
		const container = sliderContainerRef.current;
		if (!container) return;

		const scrollLeft = container.scrollLeft;
		const scrollWidth = container.scrollWidth;
		const clientWidth = container.clientWidth;

		const scrollPercentage = scrollLeft / (scrollWidth - clientWidth);

		if (scrollPercentage <= THRESHOLD) {
			setIsLeftArrowHidden(true);
			container.classList.add("right-shadow");
			container.classList.remove("left-shadow", "both-side-shadow");
		} else if (scrollPercentage >= 1 - THRESHOLD) {
			setIsRightArrowHidden(true);
			container.classList.add("left-shadow");
			container.classList.remove("right-shadow", "both-side-shadow");
		} else {
			setIsLeftArrowHidden(false);
			setIsRightArrowHidden(false);
			container.classList.add("both-side-shadow");
			container.classList.remove("left-shadow", "right-shadow");
		}
	};

	useEffect(() => {
		const container = sliderContainerRef.current;
		if (container) {
			container.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener("scroll", handleScroll);
			}
		};
	}, []);

	return (
		<>
			<div
				className='app-flex slider-title'
				style={{
					justifyContent: "space-between",
					height: "auto",
					width: `${width}%`,
				}}
			>
				<div className='app-flex' style={{ gap: "5px"}}>
					<h3 className='fw-bolder'>{sliderTitle}</h3>
					<span className='app-flex scroll-span'>
						<IconButton
							aria-label='move-left'
							size='small'
							style={{
								color: "#4baaad",
								display: isLeftArrowHidden ? "none" : "block",
							}} // Hide based on state
							onClick={handleScrollLeft}
						>
							<ArrowBackIosIcon />
						</IconButton>
						<IconButton
							aria-label='move-right'
							size='small'
							style={{
								color: "#f99d1c",
								display: isRightArrowHidden ? "none" : "block",
							}} // Hide based on state
							onClick={handleScrollRight}
						>
							<ArrowForwardIosIcon />
						</IconButton>
					</span>
				</div>
				<Link to={seeMoreLink} style={{ color: "#4baaad" }}>
					{seeMoreLink ? (
						<p>
							{seeMoreText ? seeMoreText : "Voir plus"} <ArrowRightAltIcon />
						</p>
					) : (
						""
					)}
				</Link>
			</div>
			<div
				className='slider-container app-flex'
				style={{ justifyContent: "flex-start", width: `${width}%` }}
				ref={sliderContainerRef}
				onScroll={
					isShadowOnScroll ? handleScroll : () => console.log("rien à faire")
				}
			>
				{children}
			</div>
		</>
	);
};

export default CarouselWrapper;
