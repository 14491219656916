import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";

function handleClick(event) {
	event.preventDefault();
	// console.info("You clicked a breadcrumb.");
}

const CrumbsBar = ({ routeLinks }) => {
	return (
		<div role='presentation' onClick={handleClick}>
			<Breadcrumbs
				maxItems={2}
				aria-label='breadcrumb'
				sx={{ color: "#f99d1c" }}
				separator='›'
			>
				<Link underline='hover' to='/'>
					<Typography color='text.secondary'>
						<HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
						Accueil
					</Typography>
				</Link>
				{routeLinks.map((item, key) => (
					<Link underline='hover' to={item.link} key={key}>
						<Typography color='text.secondary'>
							{item.icon}
							{item.name}
						</Typography>
					</Link>
				))}
			</Breadcrumbs>
		</div>
	);
};

export default CrumbsBar;
