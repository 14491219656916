// app.js

import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import "./components/css/bootstrap.min.css";
import "./components/css/icons.min.css";
import "./components/css/app.min.css";
import "./components/js/bootstrap.min.js";
// import { LazyLoad } from "./components/index.js";
import { BetaTest } from "./components/index.js";
import { AnimatePresence } from "framer-motion";
import { SnackbarProvider } from "notistack";
import Fade from "@mui/material/Fade";

import Layout from "./layout";
import { CircularProgress } from "@mui/material";
import { Backdrop } from "@mui/material";

import { useMetaTags } from "react-metatags-hook";

const Signup = lazy(() => import("./pages/signup/Signup.jsx"));
const Verify = lazy(() => import("./pages/signup/verify/Verify.jsx"));
const Login = lazy(() => import("./pages/login/Login.jsx"));
const Homepage = lazy(() => import("./pages/homepage/Homepage.jsx"));
const Recoverpw = lazy(() => import("./pages/login/recoverpw/Recoverpw.jsx"));
const Modifypw = lazy(() => import("./pages/login/recoverpw/Modifypw.jsx"));
const NotFound = lazy(() => import("./pages/error/Notfound.jsx"));
const Filter = lazy(() => import("./pages/filter/Filter.jsx"));
const PostAdd = lazy(() => import("./pages/post/PostAdd.jsx"));
const PostEdit = lazy(() => import("./pages/post/PostEdit.jsx"));
const PostDetails = lazy(() => import("./pages/post/PostDetails.jsx"));
// @ts-ignore
const PostBoost = lazy(() => import("./pages/post/PostBoost.jsx"));
const PostFavorite = lazy(() => import("./pages/post/PostFavorite.jsx"));
const Profile = lazy(() => import("./pages/user/Profile.jsx"));
const Contact = lazy(() => import("./pages/contact/Contact.jsx"));
const Terms = lazy(() => import("./pages/terms/Terms.jsx"));
const Privacy = lazy(() => import("./pages/privacy/Privacy.jsx"));
const Rules = lazy(() => import("./pages/rules/Rules.jsx"));
const Faq = lazy(() => import("./pages/faq/Faq.jsx"));
const Sell = lazy(() => import("./pages/sell/Sell.jsx"));
const Buy = lazy(() => import("./pages/buy/Buy.jsx"));
const Security = lazy(() => import("./pages/security/Security.jsx"));
const Boost = lazy(() => import("./pages/boost/Boost.jsx"));
const AdminHome = lazy(() => import("./pages/admin/AdminHome.jsx"));
const AdminUser = lazy(() => import("./pages/admin/AdminUser.jsx"));
const AdminReports = lazy(() => import("./pages/admin/AdminReports.jsx"));
const AdminPosts = lazy(() => import("./pages/admin/AdminPosts.jsx"));
const AdminRatings = lazy(() => import("./pages/admin/AdminRatings.jsx"));

function CustomSuspense({ children }) {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Simuler un chargement avec un délai
		const timeout = setTimeout(() => {
			setLoading(false);
		}, 460);

		// Nettoyage
		return () => clearTimeout(timeout);
	}, []);

	return (
		<>
			{loading && (
				<Backdrop open={true} sx={{ zIndex: "9999" }}>
					<CircularProgress color='warning' />
				</Backdrop>
			)}
			<Suspense
				fallback={
					<div
						className={`custom_content ${
							loading ? "blur" : "custom_content-loaded"
						}`}
					>
						{children}
					</div>
				}
			>
				<div
					className={`custom_content ${
						loading ? "blur" : "custom_content-loaded"
					}`}
				>
					{children}
				</div>
			</Suspense>
		</>
	);
}

function App() {
	// useMetaTags(
	// 	{
	// 		title: "Dealo - Votre site de petites annonces gratuites en ligne",
	// 		description:
	// 			"Dealo - Votre site de petites annonces gratuites en ligne. Trouvez des articles neufs et usagés à vendre ou achetez des articles usagés près de chez vous",
	// 		metas: [
	// 			{
	// 				name: "title",
	// 				content: "Dealo - Votre site de petites annonces gratuites en ligne",
	// 			},
	// 			{
	// 				name: "description",
	// 				content:
	// 					"Dealo - Votre site de petites annonces gratuites en ligne. Trouvez des articles neufs et usagés à vendre ou achetez des articles usagés près de chez vous",
	// 			},
	// 		],
	// 		openGraph: {
	// 			type: "website",
	// 			url: "https://dealo.re/",
	// 			title: "Dealo - Votre site de petites annonces gratuites en ligne",
	// 			description:
	// 				"Dealo - Votre site de petites annonces gratuites en ligne. Trouvez des articles neufs et usagés à vendre ou achetez des articles usagés près de chez vous",
	// 			image: "https://dealo.re/dealogo.png",
	// 		},
	// 		twitter: {
	// 			card: "summary_large_image",
	// 			url: "https://dealo.re/",
	// 			title: "Dealo - Votre site de petites annonces gratuites en ligne",
	// 			description:
	// 				"Dealo - Votre site de petites annonces gratuites en ligne. Trouvez des articles neufs et usagés à vendre ou achetez des articles usagés près de chez vous",
	// 			image: "https://dealo.re/dealogo.png",
	// 		},
	// 	},
	// 	[]
	// );

	return (
		<SnackbarProvider
			maxSnack={1}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			autoHideDuration={2000}
			TransitionComponent={Fade}
		>
			{/* {!window.location.pathname.startsWith("/admin") && <BetaTest />} */}

			<Router>
				<CustomSuspense>
					<Suspense
						fallback={
							<Backdrop open={true} sx={{ zIndex: "9999" }}>
								<CircularProgress color='warning' />
							</Backdrop>
						}
					>
						{/* {!loading && ( */}
						<AnimatePresence>
						<Routes>
							{/* to include header and footer , wrap the Routes component in Layout component ,  */}
							{/* to hide header and footer , pass showHeader and showFooter props to Layout component */}
							
							{/* home */}
							<Route path='/' element={<Layout><Homepage /></Layout>} />
							
							{/* filter */}
							<Route path='/filter' element={<Layout showHeader={true} showFooter={true}><Filter/></Layout>} />

							{/* signup  and login*/}
							<Route path='/signup' element={<Layout showHeader={false} showFooter={false}><Signup/></Layout>} />
							<Route path='/signup/verify' element={<Layout showHeader={false} showFooter={false}><Verify/></Layout>} />
							<Route path='/login' element={<Layout showHeader={false} showFooter={false}><Login/></Layout>} />
							<Route path='/login/recoverpw' element={<Layout showHeader={false} showFooter={false}><Recoverpw/></Layout>} />
							<Route path='/login/modifypw' element={<Layout showHeader={false} showFooter={false}><Modifypw/></Layout>} />

							{/* post */}
							<Route path='/post/add' element={<Layout showHeader={true} showFooter={true}><PostAdd/></Layout>} />
							<Route path='/post/details/:id' element={<Layout showHeader={true} showFooter={true}><PostDetails/></Layout>} />
							<Route path='/post/favorite' element={<Layout showHeader={true} showFooter={true}><PostFavorite/></Layout>} />
							<Route path='/post/edit/:id' element={<Layout showHeader={true} showFooter={true}><PostEdit/></Layout>} />
							<Route path='/post/boost/:id' element={<Layout showHeader={true} showFooter={true}><PostBoost/></Layout>} />

							{/* user */}
							<Route path='/user/profile' element={<Layout showHeader={true} showFooter={true}><Profile/></Layout>} />
							<Route path='/user/profile/userid/:userid' element={<Layout showHeader={true} showFooter={true}><Profile/></Layout>} />

							{/* contact */}
							<Route path='/contact' element={<Layout showHeader={true} showFooter={true}><Contact/></Layout>} />

							{/* sell */}
							<Route path='/sell' element={<Layout showHeader={true} showFooter={true}><Sell/></Layout>} />

							{/* buy */}
							<Route path='/buy' element={<Layout showHeader={true} showFooter={true}><Buy/></Layout>} />

							{/* security */}
							<Route path='/security' element={<Layout showHeader={true} showFooter={true}><Security/></Layout>} />

							{/* boost */}
							<Route path='/boost' element={<Layout showHeader={true} showFooter={true}><Boost/></Layout>} />

							{/* faq */}
							<Route path='/faq' element={<Layout showHeader={true} showFooter={true}><Faq/></Layout>} />

							{/* rules */}
							<Route path='/rules' element={<Layout showHeader={true} showFooter={true}><Rules/></Layout>} />

							{/* terms */}
							<Route path='/terms' element={<Layout showHeader={true} showFooter={true}><Terms/></Layout>} />

							{/* privacy */}
							<Route path='/privacy' element={<Layout showHeader={true} showFooter={true}><Privacy/></Layout>} />

							{/* admin */}
							<Route path='/admin' element={<Layout showHeader={false} showFooter={false} showAdminHeader={true} showAdminFooter={true} childrenAdmin={<AdminHome/>} />} />
							<Route path='/admin/user' element={<Layout showHeader={false} showFooter={false} showAdminHeader={true} showAdminFooter={true} childrenAdmin={<AdminUser/>} />} />
							<Route path='/admin/reports' element={<Layout showHeader={false} showFooter={false} showAdminHeader={true} showAdminFooter={true} childrenAdmin={<AdminReports/>} />} />
							<Route path='/admin/posts' element={<Layout showHeader={false} showFooter={false} showAdminHeader={true} showAdminFooter={true} childrenAdmin={<AdminPosts/>} />} />
							<Route path='/admin/ratings' element={<Layout showHeader={false} showFooter={false} showAdminHeader={true} showAdminFooter={true} childrenAdmin={<AdminRatings/>} />} />

							{/* 404 error */}
							<Route path="*" element={<NotFound />} />
						</Routes>
						</AnimatePresence>
						{/* )} */}
					</Suspense>
				</CustomSuspense>
			</Router>
		</SnackbarProvider>
	);
}

export default App;
