import React from "react";
import bgImage from "../dealo/bg-dealo.png";

export default function Background({ children }) {
	return (
		<div>
			<img
				src={bgImage}
				alt='Background Image'
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					objectFit: "cover",
					zIndex: -1,
					filter: "blur(10px)",
				}}
			/>
			<div
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundColor: "rgba(0, 0, 0, 0.7)",
				}}
			/>
			{children}
		</div>
	);
}
