import React, { lazy, Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const LazyLoad = (WrappedComponent) => {
	const LazyWrappedComponent = lazy(() => import(`${WrappedComponent}`));

	return (props) => (
		<Suspense fallback={<CircularProgress />}>
			<LazyWrappedComponent {...props} />
		</Suspense>
	);
};

export default LazyLoad;
