import React, { useState, useEffect } from "react";
import logo from "../dealo/dealo-text.png";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AdminFooter = () => {
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	const [role, setRole] = useState(null);

	// recuperer les informations de l'utilisateur depuis le jwt
	useEffect(() => {
		const jwtData = localStorage.getItem("jwt");
		if (jwtData) {
			const decodedJWT = jwtDecode(jwtData);
			setUser(decodedJWT);
			setRole(decodedJWT.accountType);
		} else {
			navigate("/"); // Redirigez si aucun JWT n'est présent
		}
	}, [navigate]);

	// console.log(role);

	useEffect(() => {
		if (role) {
			if (role !== "admin") {
				navigate("/"); // Redirigez si l'utilisateur n'est pas un administrateur
			}
		}
	}, [navigate, user]);

	return (
		<footer
			style={{
				position: "fixed",
				bottom: "0",
				width: "100%",
				padding: "7px",
				borderTop: "1px solid #f1f1f1",
				backgroundColor: "#fcfcfc",
			}}
			className='d-flex align-items-center'
		>
			<div>
				<div style={{ padding: "2px" }}>
					<img
						src={logo}
						alt='Dealo'
						height='25'
						onClick={() => (window.location.href = "/")}
						style={{ cursor: "pointer" }}
					/>
					<i className='text-muted text-center' style={{ fontSize: "9px" }}>
						&nbsp;&nbsp;© {new Date().getFullYear()} Tout droits réservés.
					</i>
				</div>
			</div>
		</footer>
	);
};

export default AdminFooter;
