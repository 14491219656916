import React from "react";
import logo from "../dealo/dealo-text.png";

export default function Footer() {
	return (
		<footer className='footer_dealo mt-3 pt-3 bg-white d-none d-lg-block'>
			{/* Section: Liens */}
			<section className='text-muted'>
				<div className='container text-center text-md-start'>
					{/* Grid row */}
					<div className='row mt-3'>
						{/* Grid column */}
						<div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
							{/* Contenu */}
							<div className='mb-4'>
								<img
									src={logo}
									alt='Dealo'
									width='100'
									onClick={() => (window.location.href = "/")}
									style={{
										cursor: "pointer",
										opacity: 0,
										transition: "opacity 1s ease-out",
										animation: "fadein 5s",
										animationFillMode: "forwards",
									}}
								/>
							</div>
							<p className='text-reset'>
								Copyright © {new Date().getFullYear()} Dealo
							</p>
							<p>
								<a href='/contact' className='text-dark'>
									<i className='fas fa-envelope me-3'></i>Nous contactez ?
								</a>
							</p>
							{/* <p>
								<a href='#!' className='text-dark'>
									<i className='fas fa-phone-alt me-3'></i>06 77 06 03 67
								</a>
							</p> */}
							{/* <p>
								<a href='#!' className='text-dark'>
									<i className='fas fa-map-marker-alt me-3'></i>6 Rue Emile
									Duport
								</a>
							</p> */}
						</div>
						{/* Grid column */}

						{/* Grid column */}
						<div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
							{/* Liens */}
							<h6 className='text-uppercase fw-bold mb-4'>
								<i className='fas fa-lightbulb me-3'></i> Les Conseils
							</h6>
							<p>
								<a href='/sell' className='text-dark'>
									<i className='fas fa-hand-holding-usd me-3'></i> Vendre
								</a>
							</p>
							<p>
								<a href='/buy' className='text-dark'>
									<i className='fas fa-shopping-cart me-3'></i> Acheter
								</a>
							</p>
							<p>
								<a href='/security' className='text-dark'>
									<i className='fas fa-shield-alt me-3'></i> Sécurité
								</a>
							</p>
							{/* <p>
								<a href='/boost' className='text-dark'>
									<i className='fas fa-rocket me-3'></i> Boost annonce
								</a>
							</p> */}
						</div>
						{/* Grid column */}

						{/* Grid column */}
						<div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
							{/* Liens */}
							<h6 className='text-uppercase fw-bold mb-4'>
								<i className='fas fa-life-ring me-3'></i> Centre d'aide
							</h6>
							<p>
								<a href='/faq' className='text-dark'>
									<i className='fas fa-question-circle me-3'></i>FAQ
								</a>
							</p>
							<p>
								<a href='/rules' className='text-dark'>
									<i className='fas fa-gavel me-3'></i>Règlement général
								</a>
							</p>
							<p>
								<a href='/terms' className='text-dark'>
									<i className='fas fa-file-contract me-3'></i> Condition
									d'utilisations
								</a>
							</p>
							<p>
								<a href='/privacy' className='text-dark'>
									<i className='fas fa-user-secret me-3'></i> Politique de
									confidentialité
								</a>
							</p>
						</div>
						{/* Grid column */}

						{/* Additional grid column */}
						<div className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
							<h6 className='text-uppercase fw-bold mb-4'>
								<i className='fas fa-star me-3'></i> Top catégories
							</h6>
							<p>
								<a href='/filter?category=1' className='text-dark'>
									<i className='fas fa-building me-3'></i>Immobilier
								</a>
							</p>
							<p>
								<a
									href='/filter?category=5&subcategory=21'
									className='text-dark'
								>
									<i className='fas fa-mobile-alt fa-lg me-3'></i>Smartphones
								</a>
							</p>
							<p>
								<a
									href='/filter?category=5&subcategory=23'
									className='text-dark'
								>
									<i className='fas fa-laptop fa-sm me-3'></i>Ordinateurs
								</a>
							</p>
							<p>
								<a
									href='/filter?category=2&subcategory=6'
									className='text-dark'
								>
									<i className='fas fa-car me-3'></i>Voitures
								</a>
							</p>
						</div>
						{/* Additional grid column */}
					</div>
					{/* Grid row */}
				</div>

				{/* Copyright */}
				<div className='text-center p-2'>
					© {new Date().getFullYear()}{" "}
					<a
						className='text-reset fw-bold'
						href={window.config.REACT_APP_WEBSITE_URL}
					>
						{window.config.REACT_APP_WEBSITE_NAME}
					</a>
				</div>
				{/* Copyright */}
			</section>
			{/* Section: Liens */}
		</footer>
	);
}
