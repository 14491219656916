import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { FilterCanvas } from "../../components";
import { LayoutToggleBtn } from "../../components";
import { useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import "./FilterCanvas.css";

const FilterBar = ({
	a = true,
	b = true,
	c = true,
	d = true,
	onPrecisionChange,
	onRegionChange,
}) => {
	const [isIphone, setIsIphone] = useState(false);

	useEffect(() => {
		setIsIphone(/iPhone/i.test(navigator.userAgent));
	}, []);

	const matches = useMediaQuery("(min-width:361px)");
	const [searchParams] = useSearchParams();

	const [precision, setPrecision] = useState(
		() => Cookies.get("precision") || Cookies.set("precision", "DESC")
	);

	const [region, setRegion] = useState([]); // Initialize region state with an empty array
	const [selectedRegion, setSelectedRegion] = useState("");

	// recuperer les regions

	const fetchRegions = async () => {
		try {
			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/get/region`
			);
			const data = await response.json();
			setRegion(data);
		} catch (error) {
			console.error("Error fetching regions:", error);
		}
	};

	useEffect(() => {
		fetchRegions();
	}, []);

	useEffect(() => {
		const regionParam = searchParams.get("region"); // Get region parameter from URL
		if (regionParam) {
			setSelectedRegion(regionParam); // Set selectedRegion state with the value from URL
		}
	}, [searchParams]);

	const handleRegionChange = (regionValue) => {
		setSelectedRegion(regionValue);
		onRegionChange(regionValue);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					alignItems: "center",
					gap: "5px",
				}}
			>
				<div style={{ display: c ? "block" : "none" }}>
					<FilterCanvas />
				</div>

				<form>
					<div
						style={{
							position: "relative",
							margin: "8px 5px 8px 0px",
							minWidth: "120px",
							display: a ? "block" : "none",
						}}
					>
						<select
							id='region'
							name='region'
							onChange={(e) => {
								handleRegionChange(e.target.value);
							}}
							value={selectedRegion}
							style={{ paddingLeft: "20px" }}
							className='custom_select app-flex'
						>
							<option value=''>Choix de Région...</option>
							{region.map((item) => (
								<option key={item.id} value={item.id}>
									{item.name_region}
								</option>
							))}
						</select>
						{!isIphone && (
							<i
								className='fas fa-map-marker-alt'
								style={{
									position: "absolute",
									left: "10px",
									top: "50%",
									transform: "translateY(-50%)",
								}}
							></i>
						)}
					</div>
				</form>

				<form
					style={{
						minWidth: "100px",
						display: b ? "block" : "none",
					}}
				>
					<select
						id='précision'
						name='précision'
						placeholder='Précision'
						onChange={(e) => {
							onPrecisionChange(e.target.value);
							setPrecision(e.target.value);
							Cookies.set("precision", e.target.value, { expires: 7 });
							window.location.reload();
						}}
						value={precision}
						className='custom_select app-flex'
					>
						<option value='DESC'>Plus récentes</option>
						<option value='ASC'>Plus anciennes</option>
						<option value='CROISSANT'>Prix croissants</option>
						<option value='DECROISSANT'>Prix décroissants</option>
					</select>
				</form>

				<div style={{ display: d ? "block" : "none" }}>
					<div style={{ display: matches ? "block" : "none" }}>
						<LayoutToggleBtn />
					</div>
				</div>
			</Box>
		</Box>
	);
};

export default FilterBar;
