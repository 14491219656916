import React from "react";

export function convertImageToPNG(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (event) {
			const img = new Image();
			img.onload = function () {
				const canvas = document.createElement("canvas");
				canvas.width = img.width;
				canvas.height = img.height;
				const ctx = canvas.getContext("2d");
				ctx.drawImage(img, 0, 0);
				const dataURL = canvas.toDataURL("image/png");
				fetch(dataURL)
					.then((res) => res.blob())
					.then((blob) => {
						const pngFile = new File(
							[blob],
							file.name.replace(/\.[^/.]+$/, "") + ".png",
							{ type: "image/png" }
						);
						resolve(pngFile);
					});
			};
			img.src = event.target.result;
		};
		reader.onerror = (error) => reject(error);
		reader.readAsDataURL(file);
	});
}

const ConvertImageToPNG = () => {
	return <div></div>;
};

export default ConvertImageToPNG;
