import React from "react";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import Skeleton from "@mui/material/Skeleton";

import "./DataImageCard.css";

const DataImageCard = ({ data, isLoading }) => {
	return (
		<>
			{isLoading ? (
				<div className='data-card-container app-flex' style={{ gap: "15px" }}>
					<div className='data-card app-flex'>
						<Skeleton variant='rectangular' width={400} height={300} />
					</div>
				</div>
			) : (
				<div className='data-card app-flex'>
					<img
						src={data.imageUrl}
						alt='Data Image card'
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
					/>
					<div
						className='item-name app-flex'
						style={{
							alignItems: "center",
							flexDirection: "column",
							justifyContent: "flex-end",
						}}
					>
						<p>{data.name}</p>
						{data.counting >= 1 && (
							<span className='app-flex info-span'>
								<AllInboxIcon fontSize='small' />
								<p>{data.counting} annonce(s)</p>
							</span>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default DataImageCard;
