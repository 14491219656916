import React from "react";

const BetaTest = () => {
	return (
		<button
			style={{
				position: "fixed",
				top: 0,
				left: 0,
				opacity: 0.5,
                zIndex: 9999,
                padding: "5px",
                margin : "5px",
				cursor: "not-allowed",
			}}
			disabled
            className="btn btn-sm btn-warning"
		>
			VERSION TEST
		</button>
	);
};

export default BetaTest;
