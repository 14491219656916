import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewDayIcon from "@mui/icons-material/ViewDay";
import { jwtDecode } from "jwt-decode";
import Fragment from "react";

const LayoutToggleBtn = ({ onLayouthange }) => {
	const [layout, setLayout] = useState("");

	useEffect(() => {
		const storedLayout = localStorage.getItem("layout")
			? jwtDecode(localStorage.getItem("layout")).layout
			: "PostCard";
		setLayout(storedLayout);
		// console.log(storedLayout); // Log the layout after it's set
	}, []);

	const toggleLayout = () => {
		const newLayout = layout === "PostCard" ? "PostList" : "PostCard";
		setLayout(newLayout);
		updateJWTLayout(newLayout);
		// reload the page
		window.location.reload();
		// console.log(newLayout);
	};

	const updateJWTLayout = async (newLayout) => {
		try {
			let layoutToken = localStorage.getItem("layout");
			// console.log(newLayout);
			if (!layoutToken) {
				layoutToken = createJWTToken(
					{ layout: newLayout },
					'c94JCgk2e46CgS'
				);
				// console.log(jwtDecode(layoutToken));
			} else {
				layoutToken = updateJWTToken(layoutToken, newLayout);
				// console.log(jwtDecode(layoutToken));
			}

			localStorage.setItem("layout", layoutToken);
		} catch (error) {
			console.error("Error updating JWT layout:", error.message);
		}
	};

	const createJWTToken = (payload, secret) => {
		const header = {
			alg: "HS256",
			typ: "JWT",
		};

		const encodedHeader = btoa(JSON.stringify(header));
		const encodedPayload = btoa(JSON.stringify(payload));

		const signature =
			encodedHeader + "." + encodedPayload + "." + window.btoa(secret);

		return signature;
	};

	const updateJWTToken = (layoutToken, newLayout) => {
		const decodedToken = jwtDecode(layoutToken);
		decodedToken.layout = newLayout;
		const encodedPayload = btoa(JSON.stringify(decodedToken));
		const header = layoutToken.split(".")[0];
		const signature = layoutToken.split(".")[2];
		return header + "." + encodedPayload + "." + signature;
	};

	return (
		<React.Fragment>
			<button
				onClick={toggleLayout}
				style={{
					border: "1.5px solid #60ba786a",
					backgroundColor: "#aeffc32b",
					color: "rgb(4, 14, 8)",
					outline: "none",
					fontWeight: "600",
					padding: "4px 10px",
					borderRadius: "10px",
				}}
			>
				{layout === "PostCard" ? (
					<>
						<ViewDayIcon
							sx={{
								fontSize: "20px",
								marginBottom: "1px",
							}}
						/>
						&nbsp;Liste
					</>
				) : (
					<>
						<ViewModuleIcon
							sx={{
								fontSize: "20px",
								marginBottom: "1px",
							}}
						/>
						&nbsp;Galerie
					</>
				)}
			</button>
		</React.Fragment>
	);
};

export default LayoutToggleBtn;
