const ProhibitedWords = [
	// Mots vulgaires
	"merde",
	"putain",
	"con",
	"salope",
	"enculé",
	"connard",
	"bordel",
	"foutre",
	"niquer",
	"cul",
	"bite",
	"sexe",
	"chatte",
	"nique",
	"zizi",
	"nudité",
	// Mots violents
	"viol",
	"meurtre",
	"tuer",
	"assassinat",
	"arme",
	"attaque",
	"agression",
	"violence",
	"guerre",
	// Mots discriminatoires
	"racisme",
	"sexisme",
	"homophobie",
	"antisémite",
	"islamophobie",
	"discrimination",
	// Mots faisant référence à des drogues
	"drogue",
	"cannabis",
	"héroïne",
	"cocaïne",
	"marijuana",
	"ecstasy",
	"LSD",
	// Mots liés à la pornographie
	"pornographie",
	"porno",
	"sexe",
	"nudité",
	"érotique",
	// Mots offensants
	"arnaque",
	"escroquerie",
	"fraude",
	"contrefaçon",
	"trafic",
	"prostitution",
	"escroquerie",
	"arnaque",
	"faux",
	"contrefait",
	// Mots blasphématoires
	"blasphème",
	"satan",
	"démon",
	"maudit",
	"infernal",
	"hérétique",
	// Insultes et termes dégradants
	"imbécile",
	"abruti",
	"débile",
	"crétin",
	"idiot",
	"stupide",
	"minable",
	"pathétique",
	// Mots incitant à la haine
	"haine",
	"odieux",
	"dégoûtant",
	"répugnant",
	"nauséabond",
	"ignoble",
	"méprisable",
	"raciste",
	"antisémite",
	"homophobe",
	"islamophobe",
	// Mots liés à la santé mentale
	"dépression",
	"suicide",
	"anxiété",
	"toxicomanie",
	"alcoolisme",
	// Termes offensants ou inappropriés en contexte professionnel
	"problème",
	"bug",
	"erreur",
	"échec",
	"défaillance",
	"raté",
	"incompétent",
	"incapable",
	"insatisfait",
	// Mots indésirables en contexte commercial
	"arnaque",
	"faux",
	"contrefait",
	"escroquerie",
	"fraude",
	"piratage",
	"plagiat",
	"spam",
	"pourriel",
	"malware",
	"virus",
	// Mots liés à des sujets sensibles ou délicats
	"cancer",
	"maladie",
	"handicap",
	"pauvreté",
	"chômage",
	"décès",
	"divorce",
	"abandon",
	"traumatisme",
	"séparation",
	// Termes violents ou agressifs en contexte de jeu ou de compétition
	"kill",
	"meurtre",
	"massacre",
	"battre",
	"détruire",
	"annihiler",
	"écraser",
	"vaincre",
	"anéantir",
	"exterminer",
	// Mots associés à des activités illégales
	"vol",
	"braquage",
	"cambriolage",
	"criminel",
	"escroc",
	"trafiquant",
	"voleur",
	"voyou",
	// Mots liés à des comportements dangereux ou irresponsables
	"dangereux",
	"risqué",
	"irresponsable",
	"imprudent",
	"téméraire",
	"inconscient",
	// Termes politiquement chargés ou controversés
	"extrémisme",
	"dictature",
	"totalitarisme",
	"fascisme",
	"nazisme",
	"communisme",
	"capitalisme",
	"liberté",
	"égalité",
	"fraternité",
	// Mots liés à des sujets controversés ou sensibles
	"religion",
	"politique",
	"sexualité",
	"avortement",
	"mariage",
	"éthique",
	"morale",
	"croyance",
	// Mots offensants ou dégradants envers des groupes ethniques ou religieux
	"juif",
	"musulman",
	"chrétien",
	"athée",
	"catholique",
	"protestant",
	"hindou",
	"bouddhiste",
	"sikh",
	"animiste",
	// Termes associés à des événements traumatisants ou catastrophiques
	"attentat",
	"catastrophe",
	"tragédie",
	"accident",
	"désastre",
	"cataclysme",
	"calamité",
	// Mots liés à des comportements socialement inacceptables
	"harcèlement",
	"intimidation",
	"agression",
	"abus",
	"exploitation",
	"discrimination",
	// Termes offensants ou insultants envers des groupes sociaux ou professionnels
	"pauvre",
	"richard",
	"chômeur",
	"assisté",
	"parasite",
	"glandeur",
	"feignant",
	"improductif",
	"inefficace",
	"inutile",
	// Mots liés à des comportements déviants ou criminels
	"pédophilie",
	"viol",
	"inceste",
	"trafic",
	"proxénétisme",
	"esclavage",
	"crime",
	"délit",
	"délinquant",
	"criminalité",
	// Termes péjoratifs ou insultants envers des personnes ou des groupes
	"handicapé",
	"malade",
	"vieux",
	"gros",
	"maigre",
	"moche",
	"stérile",
	"sourd",
	"aveugle",
	"sourd-muet",
	// Mots liés à des sujets tabous ou controversés
	"inceste",
	"nécrophilie",
	"zoophilie",
	"coprophilie",
	"nécrophagie",
	"nécrophilie",
	"pédophilie",
	"toxicomanie",
	"alcoolisme",
	// Mots liés à des comportements sexuels déviants ou répréhensibles
	"prostitution",
	"viol",
	"abus",
	"harcèlement",
	"perversité",
	"dégénérescence",
	// Mots associés à des maladies mentales ou des troubles psychiatriques
	"schizophrénie",
	"psychopathe",
	"paranoïa",
	"maniaco-dépression",
	"bipolaire",
	"autiste",
	// Termes injurieux ou diffamatoires envers des individus ou des groupes
	"menteur",
	"tricheur",
	"voleur",
	"faux",
	"hypocrite",
	"lâche",
	"couard",
	"salaud",
	"ordure",
	"pourriture",
	"criminel",
	// Mots vulgaires ou grossiers
	"cul",
	"bite",
	"zizi",
	"chatte",
	"sexe",
	"niquer",
	"nique",
	"sucer",
	"enculer",
	"foutre",
	"chier",
	// Termes péjoratifs ou insultants envers des minorités ethniques ou culturelles
	"arabe",
	"noir",
	"asiatique",
	"blanc",
	"latino",
	"africain",
	"européen",
	"américain",
	"indigène",
	"aborigène",
	"métis",
	// Mots offensants ou dégradants envers des groupes religieux ou spirituels
	"chrétien",
	"juif",
	"musulman",
	"hindou",
	"bouddhiste",
	"sataniste",
	"païen",
	"athée",
	// Termes vulgaires ou impolis envers des individus ou des groupes
	"connard",
	"enfoiré",
	"salopard",
	"salopard",
	"batard",
	"taré",
	"débile",
	"crétin",
	"abruti",
	"abruti",
	"idiot",
	"imbécile",
	// Mots désobligeants ou irrespectueux envers des personnes âgées
	"vieillard",
	"vieux",
	"ridicule",
	"sénile",
	"infirmier",
	// Termes vulgaires ou impolis envers des femmes
	"pute",
	"salope",
	"pouffiasse",
	"garce",
	"trainée",
	"connasse",
	"cochonne",
	// Mots désobligeants ou irrespectueux envers des hommes
	"pédé",
	"pédale",
	"tapette",
	"enculé",
	"connard",
	"couille",
	"bouffon",
	// Termes péjoratifs ou insultants envers des personnes handicapées
	"handicapé",
	"invalide",
	"infirme",
	"débile",
	"crétin",
	"abruti",
	"stupide",
	// Mots grossiers ou vulgaires envers des personnes de sexe féminin
	"pétasse",
	"pouffe",
	"grosse",
	"boudin",
	"triso",
	// Termes désobligeants ou irrespectueux envers des personnes de sexe masculin
	"gros",
	"bouffon",
	"bouseux",
	"larve",
	"minus",
	"sous-merde",
	// Mots vulgaires ou grossiers envers des personnes de genres non-binaires
	"it",
	"autist",
	"connard",
	"taré",
	"abruti",
	"salope",
	// Mots péjoratifs ou insultants envers des personnes d'orientation sexuelle différente
	"pédé",
	"gouine",
	"lesbienne",
	"homosexuel",
	"homo",
	"bi",
	// Mots vulgaires ou impolis envers des personnes d'origines différentes
	"bougnoule",
	"nègre",
	"bicot",
	"youpin",
	// Mots offensants ou dégradants envers des personnes de cultures ou religions différentes
	"mécréant",
	"koufar",
	"infidèle",
	"hérétique",
	"païen",
	// Termes vulgaires ou grossiers envers des personnes d'identités de genre non-conventionnelles
	"tranny",
	"transexuel",
	"trans",
	"transgenre",
	// Mots vulgaires ou grossiers envers des personnes d'âges différents
	"bambin",
	"gamin",
	"vieux",
	// Termes vulgaires ou grossiers envers des personnes d'apparences ou de corpulences différentes
	"gras",
	"obèse",
	"maigrelet",
	"nain",
	// Mots vulgaires ou grossiers envers des personnes de capacités physiques ou mentales différentes
	"infirme",
	"estropié",
	"grabataire",
	// Mots vulgaires ou grossiers envers des personnes de religions ou croyances différentes
	"hérétique",
	"païen",
	"infidèle",
	// Termes vulgaires ou grossiers envers des personnes de nationalités différentes
	"étranger",
	"étranger",
	"expatrié",
	"immigré",
	// Mots vulgaires ou grossiers envers des personnes de professions ou statuts différents
	"chômeur",
	"assisté",
	"sans-abri",
	"assisté",
	"parasite",
	"feignant",
	// Mots vulgaires ou grossiers envers des personnes de situations familiales différentes
	"orphelin",
	"divorcé",
	"célibataire",
	"veuf",
	// Mots vulgaires ou grossiers envers des personnes de régions ou origines différentes
	"campagnard",
	"plouc",
	"cul-terreux",
	"montagnard",
	// Termes vulgaires ou grossiers envers des personnes de capacités intellectuelles ou mentales différentes
	"idiot",
	"débile",
	"crétin",
	"imbécile",
	"attardé",
	// Mots vulgaires ou grossiers envers des personnes d'habitudes ou de comportements différents
	"alcoolique",
	"toxicomane",
	"fumeur",
	"junkie",
	"sdf",
	// Mots vulgaires ou grossiers envers des personnes de situations sociales ou financières différentes
	"pauvre",
	"miséreux",
	"indigent",
	"sans-le-sou",
	// Termes vulgaires ou grossiers envers des personnes de styles de vie ou d'apparences différents
	"marginal",
	"punk",
	"gothique",
	"hipster",
	// Mots vulgaires ou grossiers envers des personnes de niveaux d'éducation ou de connaissances différents
	"illettré",
	"ignorant",
	"inculte",
	"crétin",
	// Termes vulgaires ou grossiers envers des personnes de préférences ou orientations sexuelles différentes
	"pédé",
	"lesbo",
	"bifleur",
	"fisteur",
	"enculeur",
	"bouffeur",
	"brouteur",
	// Mots vulgaires ou grossiers envers des personnes de cultures ou traditions différentes
	"primitif",
	"sauvage",
	"barbare",
	"arriéré",
	// Termes vulgaires ou grossiers envers des personnes de caractéristiques physiques ou physiologiques différentes
	"boudin",
	"triso",
	"tarlouze",
	"thons",
	"boudin",
	"tarlouze",
	// Mots vulgaires ou grossiers envers des personnes de statuts professionnels ou sociaux différents
	"assisté",
	"salaud",
	"trou du cul",
	"batard",
	"ordure",
	// Mots vulgaires ou grossiers envers des personnes de croyances ou religions différentes
	"infidèle",
	"mécréant",
	"hérétique",
	"païen",
	// Termes vulgaires ou grossiers envers des personnes d'apparences ou d'identités de genre différentes
	"travelo",
	"pédale",
	"enculé",
	"gouine",
	"folle",
	// Mots vulgaires ou grossiers envers des personnes d'ethnies ou cultures différentes
	"nègre",
	"bougnoule",
	"rizière",
	"youpin",
	// Termes vulgaires ou grossiers envers des personnes d'habitudes ou de caractéristiques physiques différentes
	"bourré",
	"soûlard",
	"baveux",
	"branleur",
	"cassos",
	// Mots vulgaires ou grossiers envers des personnes de statuts sociaux ou professionnels différents
	"chômeur",
	"glandeur",
	"feignant",
	"pauvre",
	"miséreux",
	// Termes vulgaires ou grossiers envers des personnes de comportements ou de caractéristiques différentes
	"taré",
	"dingue",
	"barjo",
	"psychopathe",
	"détraqué",
	// Mots vulgaires ou grossiers envers des personnes de religions ou croyances différentes
	"athée",
	"infidèle",
	"hérétique",
	"mécréant",
	// Termes vulgaires ou grossiers envers des personnes d'orientations sexuelles ou identités de genre différentes
	"pédé",
	"gouine",
	"travelo",
	"enculé",
	"bouffeur",
	// Mots vulgaires ou grossiers envers des personnes d'origines géographiques ou culturelles différentes
	"arabe",
	"juif",
	"youpin",
	"rizière",
	// Termes vulgaires ou grossiers envers des personnes de styles de vie ou habitudes différentes
	"clochard",
	"mendiant",
	"zonard",
	"clochard",
	// Mots vulgaires ou grossiers envers des personnes de niveaux d'éducation ou de connaissances différentes
	"abruti",
	"crétin",
	"débile",
	"imbécile",
	"inculte",
	// Termes vulgaires ou grossiers envers des personnes d'origines ou cultures différentes
	"nègre",
	"bougnoule",
	"chinois",
	"sauvage",
	// Mots vulgaires ou grossiers envers des personnes de religions ou croyances différentes
	"koufar",
	"mécréant",
	"hérétique",
	// Termes vulgaires ou grossiers envers des personnes d'habitudes ou de caractéristiques physiques différentes
	"pétasse",
	"grosse",
	"maigrelet",
	// Mots vulgaires ou grossiers envers des personnes de statuts professionnels ou sociaux différents
	"larbin",
	"mouton",
	"esclave",
	// Termes vulgaires ou grossiers envers des personnes de comportements ou caractéristiques différentes
	"maboule",
	"taré",
	"dingue",
	// Mots vulgaires ou grossiers envers des personnes de styles de vie ou d'apparences différentes
	"clochard",
	"zonard",
	"punk",
	// Termes vulgaires ou grossiers envers des personnes d'habitudes ou de styles de vie différentes
	"vieux",
	"jeune",
	"plouc",
	// Mots vulgaires ou grossiers envers des personnes de niveaux sociaux ou économiques différents
	"clochard",
	"mendiant",
	"zonzon",
	// Termes vulgaires ou grossiers envers des personnes d'habitudes ou de comportements différents
	"zozo",
	"boulet",
	// Mots vulgaires ou grossiers envers des personnes de situations ou d'origines géographiques différentes
	"parigot",
	"marseillais",
	// Termes vulgaires ou grossiers envers des personnes de niveaux intellectuels ou d'éducation différents
	"ignorant",
	"inculte",
	// Mots vulgaires ou grossiers envers des personnes de comportements ou de caractéristiques différentes
	"taré",
	"dingue",
	// Termes vulgaires ou grossiers envers des personnes d'habitudes ou de caractéristiques différentes
	"baba",
	"déjanté",
	// Mots vulgaires ou grossiers envers des personnes de régions ou de cultures différentes
	"berrichon",
	"breton",
	// Termes vulgaires ou grossiers envers des personnes de niveaux sociaux ou économiques différents
	"chômeur",
	"pauvre",
	// Mots vulgaires ou grossiers envers des personnes de situations ou d'apparences différentes
	"clochard",
	"mendiant",
	// Termes vulgaires ou grossiers envers des personnes de caractéristiques ou d'habitudes différentes
	"maboule",
	"dingue",
	// Mots vulgaires ou grossiers envers des personnes de régions ou de cultures différentes
	"bourguignon",
	"marseillais",
	// Termes vulgaires ou grossiers envers des personnes de statuts professionnels ou sociaux différents
	"sdf",
	"sans-abri",
	// Mots vulgaires ou grossiers envers des personnes d'apparences ou de caractéristiques différentes
	"dingue",
	"maboule",
	// Termes vulgaires ou grossiers envers des personnes de styles de vie ou de caractéristiques différentes
	"crado",
	"clochard",
	// Mots vulgaires ou grossiers envers des personnes de situations ou d'habitudes différentes
	"zozo",
	"baba",
	// Termes vulgaires ou grossiers envers des personnes d'origines ou de cultures différentes
	"breton",
	"parigot",
	// Mots vulgaires ou grossiers envers des personnes de statuts professionnels ou sociaux différents
	"assisté",
	"bouffon",
	// Termes vulgaires ou grossiers envers des personnes de styles de vie ou de comportements différents
	"poivrot",
	"clochard",
	// Mots vulgaires ou grossiers envers des personnes de situations ou de comportements différents
	"crado",
	"barjot",
	// Termes vulgaires ou grossiers envers des personnes d'habitudes ou de caractéristiques différentes
	"clochard",
	"soûlard",
	// Mots vulgaires ou grossiers envers des personnes de niveaux sociaux ou de statuts différents
	"bouseux",
	"bourgeois",
	// Termes vulgaires ou grossiers envers des personnes d'origines ou de styles de vie différents
	"baba",
	"clochard",
	// Mots vulgaires ou grossiers envers des personnes de caractéristiques ou de comportements différents
	"baba",
	"dingue",
	// Termes vulgaires ou grossiers envers des personnes de régions ou d'habitudes différentes
	"breton",
	"parigot",
	// Mots vulgaires ou grossiers envers des personnes de statuts sociaux ou professionnels différents
	"assisté",
	"salaud",
	// Termes vulgaires ou grossiers envers des personnes de comportements ou d'habitudes différentes
	"clochard",
	"zozo",
	// Mots vulgaires ou grossiers envers des personnes de situations ou de caractéristiques différentes
	"baba",
	"crado",
	// Termes vulgaires ou grossiers envers des personnes de styles de vie ou d'apparences différentes
	"mendiant",
	"clochard",
	// Mots vulgaires ou grossiers envers des personnes de régions ou de statuts différents
	"bouseux",
	"bourgeois",
	// Termes vulgaires ou grossiers envers des personnes de comportements ou de caractéristiques différentes
	"maboule",
	"dingue",
	// Mots vulgaires ou grossiers envers des personnes d'origines ou d'habitudes différentes
	"parigot",
	"breton",
	// Termes vulgaires ou grossiers envers des personnes de styles de vie ou de statuts différents
	"salaud",
	"cassos",
	// Mots vulgaires ou grossiers envers des personnes de comportements ou d'habitudes différentes
	"clochard",
	"zozo",
	// Termes vulgaires ou grossiers envers des personnes de situations ou de caractéristiques différentes
	"baba",
	"crado",
	// Mots vulgaires ou grossiers envers des personnes de styles de vie ou d'apparences différentes
	"mendiant",
	"clochard",
	// Mots vulgaires ou grossiers envers des personnes de régions ou de statuts différents
	"bouseux",
	"bourgeois",
	// Termes vulgaires ou grossiers envers des personnes de comportements ou de caractéristiques différentes
	"maboule",
	"dingue",
	// Mots vulgaires ou grossiers envers des personnes d'origines ou d'habitudes différentes
	"parigot",
	"breton",
	// Termes vulgaires ou grossiers envers des personnes de styles de vie ou de statuts différents
	"salaud",
	"cassos",

	// mot ivoiriens
	"grenade",
	"bombe",
	"cali",
	"porno",
	"ak-47",
	"maudia",
	"maudit",
	"babiere",
	"mercon",
	"bassalor",
	// "gaou",
    "bizi",
    "cui",
    "bobitana",
    "pine",
    "kpe",
    "kpetou",
    "deboukehi",
    "deboucuehi",
    
];

export default ProhibitedWords;
