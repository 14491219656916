import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../dealo/dealo-text.png";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import "./Header.css";

//Ui Modules
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import { Subheader } from "../../components";

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .MuiBadge-badge": {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}));

export default function Header() {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const navigate = useNavigate();
	const open = Boolean(anchorEl);
	const [username, setUsername] = useState([]);
	const [profilePicture, setProfilePicture] = useState("");
	const [id_user, setId_user] = useState("");
	const [id_user_jwt, setId_user_jwt] = useState("");
	const [isLoggedIn, setIsLoggedIn] = useState("");
	const [loader, setLoader] = useState(false);
	const [pressed, setPressed] = useState(false);

	const [category, setCategory] = useState([]);

	const matches = useMediaQuery("(max-width:992px)");

	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		const cookieValue = Cookies.get("query");
		if (cookieValue) {
			setInputValue(cookieValue);
		}
	}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		const jwtData = localStorage.getItem("jwt");

		// jwt
		if (jwtData) {
			const decodedJWT = jwtDecode(jwtData);
			setUsername(decodedJWT.username);
			setProfilePicture(decodedJWT.profilePicture);
			setId_user(decodedJWT.id_user);
			setId_user_jwt(decodedJWT.id_user);
			setIsLoggedIn(true);
		}

		// category and subcategory
		fetch(`${window.config.REACT_APP_BACKEND_URL}/api/get/category`)
			.then((response) => response.json())
			.then((categories) => {
				// Initialize an empty array to hold all categories with their subcategories
				let categoriesWithSubcategories = [];

				// For each category, fetch its subcategories
				categories.forEach((category) => {
					fetch(
						`${window.config.REACT_APP_BACKEND_URL}/api/get/subcategory/${category.id}`
					)
						.then((response) => response.json())
						.then((subcategories) => {
							// Add the category and its subcategories to the array
							categoriesWithSubcategories.push({
								...category,
								subcategories: subcategories,
							});

							// If all categories have been processed, update the state
							if (categoriesWithSubcategories.length === categories.length) {
								setCategory(categoriesWithSubcategories);
							}
						})
						.catch((error) => {
							console.error("Error:", error);
						});
				});
			})
			.catch((error) => {
				console.error("Error:", error);
			});

		setLoader(true);
	}, []);

	const [deferredPrompt, setDeferredPrompt] = useState(null);
	const [isPWA, setIsPWA] = useState(false);
	const isPWAInstalled = localStorage.getItem("isPWAInstalled");

	useEffect(() => {
		const handleBeforeInstallPrompt = (event) => {
			event.preventDefault();
			setDeferredPrompt(event);
		};

		window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

		// Check if the app is running in standalone mode
		if (
			window.matchMedia("(display-mode: standalone)").matches ||
			window.navigator.standalone === true
		) {
			setIsPWA(true);
		}

		return () => {
			window.removeEventListener(
				"beforeinstallprompt",
				handleBeforeInstallPrompt
			);
		};
	}, []);

	useEffect(() => {
		if (!isPWAInstalled) {
			localStorage.setItem("isPWAInstalled", "false");
		}
	}, [isPWAInstalled]);

	const handleInstallClick = () => {
		if (deferredPrompt) {
			deferredPrompt.prompt();
			deferredPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === "accepted") {
					// Mettre à jour l'état pour indiquer que l'application est installée en tant que PWA
					localStorage.setItem("isPWAInstalled", "true");
					// window.location.reload();
					console.log("User accepted the install prompt");
				} else {
					localStorage.setItem("isPWAInstalled", "false");
					console.log("User dismissed the install prompt");
				}
				setDeferredPrompt(null);
			});
		}
	};

	// Hide header on scroll down, show on scroll up
	function useScrollDirection() {
		const [scrollDirection, setScrollDirection] = useState("up");

		useEffect(() => {
			let lastScrollY = window.pageYOffset;

			const updateScrollDirection = () => {
				const scrollY = window.pageYOffset;
				const direction = scrollY > lastScrollY ? "down" : "up";

				if (
					direction !== scrollDirection &&
					(scrollY > 0 || direction === "up")
				) {
					setScrollDirection(direction);
				}

				lastScrollY = scrollY > 0 ? scrollY : 0;
			};

			window.addEventListener("scroll", updateScrollDirection);

			return () => {
				window.removeEventListener("scroll", updateScrollDirection);
			};
		}, [scrollDirection]);

		return scrollDirection;
	}

	// console.log(category);

	// console.log(jwtDecode(localStorage.getItem("jwt")));

	// console.log(isPWAInstalled);

	const scrollDirection = useScrollDirection();

	return (
		<header
			className={`header_dealo mb-3 bg-white ${
				scrollDirection === "down" ? "hide" : ""
			}`}
		>
			{/* MENU */}
			<div className='app-flex justify'>
				<div className='d-flex custom_positioning-left-wrapper'>
					{/* DEALO LOGO PC */}

					<div
						className='navbar-brand-box'
						style={{ textAlign: matches ? "center" : "left" }}
					>
						<a href='/' className='logo'>
							<span className='logo d-none d-lg-block'>
								<img
									src={logo}
									style={{ cursor: "pointer" }}
									alt='Dealo'
									height='40'
									className='mx-5'
								/>
							</span>
						</a>
					</div>

					<div
						className='d-flex justify-content-center align-items-center custom_positioning-brand'
						style={{
							width: matches ? "100%" : "100%",
							justifyContent: "space-around",
						}}
					>
						{/* MOBILE MENU TOGGLER */}
						<button
							type='button'
							className='btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light'
							data-bs-toggle='offcanvas'
							href='#mobileoffcanvas'
							role='button'
							aria-controls='mobileoffcanvas'
						>
							<i className='fa fa-fw fa-bars'></i>
						</button>

						{/* DEALO LOGO MOBILE */}
						<div
							className='d-lg-none app-flex'
							style={{ height: "100%" }}
							onClick={() => {
								window.location.href = "/";
							}}
						>
							<img
								src={logo}
								style={{ cursor: "pointer" }}
								alt='Dealo'
								height='40'
							/>
						</div>

						{/* RIGHT SIDE MOBILE  */}
						<div className='d-lg-none d-flex justify-content-center align-items-center m-2'>
							{/* SEARCH BAR MOBILE  */}
							<div // bouton de recherche mobile
							>
								<StyledBadge
									overlap='circular'
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
								>
									<Tooltip title='Rechercher'>
										<IconButton color='inherit' onClick={handleClick}>
											<SearchIcon />
										</IconButton>
									</Tooltip>
								</StyledBadge>
							</div>
						</div>
					</div>

					{/* MOBILE MENU TOGGLER */}

					{/* SEARCH */}
					<div className='d-none d-lg-block' style={{ paddingRight: "5vh" }}>
						<form className='app-search custom-search-field'>
							<div className='position-relative'>
								<div
									style={{
										display: "flex",
										alignItems: "center",
										width: "38vw",
										height: "42.7px",
										margin: "auto",
										borderRadius: "15px",
										border: "2px solid #e3e3e3",
										padding: "2px",
									}}
								>
									<SearchIcon />
									<input
										id='search-input'
										placeholder='Rechercher sur dealo'
										value={inputValue}
										type='search'
										onChange={(ev) => setInputValue(ev.target.value)}
										onKeyPress={(ev) => {
											if (ev.key === "Enter") {
												window.location.href = `/filter?query=${ev.target.value}`;
												Cookies.set("query", ev.target.value);
												ev.preventDefault();
											}
										}}
										style={{
											flex: 1,
											border: "none",
											backgroundColor: "transparent",
											marginLeft: "10px",
											marginRight: "10px",
											outline: "none",
										}}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>

				{/* RIGHT SIDE OF THE HEADER */}

				{/* INSTALL BUTTON DESKTOP */}
				{isPWAInstalled &&
					isPWAInstalled === "false" &&
					!isPWA &&
					"serviceWorker" in navigator && (
						<Tooltip title='Installer Dealo'>
							<button
								className='d-none d-lg-block'
								onClick={handleInstallClick}
								style={{
									backgroundColor: "#61c087",
									boxShadow: "none",
									borderRadius: "20px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									padding: "9px 12px",
									border: "none",
								}}
							>
								<InstallDesktopIcon className='bx bx-flashing-hover fs-5 text-white' />
							</button>
							{/* INSTALL BUTTON DESKTOP */}
						</Tooltip>
					)}

				<div className='d-none d-lg-block'>
					{/* USER */}
					{!id_user && loader ? ( // If user is not logged in
						<div className='d-flex'>
							<div className='card-body'>
								<div className='d-flex gap-2'>
									<button
										onClick={() => navigate("/signup")}
										type='button'
										className='btn btn-success btn-rounded waves-effect waves-light'
									>
										<i className='fas fa-user-plus'></i> Inscription
									</button>
									<button
										onClick={() => navigate("/login")}
										type='button'
										className='btn btn-warning btn-rounded waves-effect waves-light'
									>
										<i className='fas fa-sign-in-alt'></i> Connexion
									</button>
								</div>
							</div>
						</div>
					) : (
						<div className='d-flex'>
							<div className='card-body'>
								<div className='d-flex gap-2'>
									<Link to='/post/add'>
										<Button
											variant='contained'
											style={{
												height: "42.7px",
												minWidth: "169.8px",
												backgroundColor: "#e0921e",
												boxShadow: "none",
												margin: "auto",
												borderRadius: "15px",
												textTransform: "none",
												fontWeight: "bolder",
												fontSize: 12,
											}}
										>
											<i className='fas fa-plus-square'>&nbsp;</i>
											Poster une annonce
										</Button>
									</Link>

									{/* USER MENU */}
									<Tooltip title='Mon compte'>
										<Button
											variant='contained'
											onClick={handleClick}
											style={{
												height: "42.7px",
												backgroundColor: "#b5b5b559",
												boxShadow: "none",
												margin: "auto",
												borderRadius: "15px",
												color: "#353839",
												display: "flex",
												alignItems: "center",
												textTransform: "none",
											}}
										>
											<b>
												<i>{username}&nbsp;</i>
											</b>
											<StyledBadge
												overlap='circular'
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												variant='dot'
											>
												<Avatar
													className='border'
													alt=""
													style={{
														width: "35px",
														height: "35px",
													}}
													src={profilePicture}
												/>
											</StyledBadge>
										</Button>
									</Tooltip>

									{window.innerWidth >= 991.5 && (
										<Menu
											anchorEl={anchorEl}
											id='account-menu'
											open={open}
											onClose={handleClose}
											onClick={handleClose}
											transformOrigin={{ horizontal: "right", vertical: "top" }}
											anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
										>
											<MenuItem
												onClick={() => {
													window.location.href = "/user/profile";
												}}
											>
												<i className='fas fa-user-circle'>&nbsp;</i> Mon profil
											</MenuItem>
											<MenuItem
												onClick={() => {
													window.location.href = "/post/favorite";
												}}
												className='text-success'
											>
												<i className='fas fa-star fs-6'>&nbsp;&nbsp;</i> Favoris
											</MenuItem>
											<MenuItem
												onClick={() => {
													localStorage.removeItem("jwt");
													enqueueSnackbar("Déconnexion réussie", {
														variant: "success",
													});
													setPressed(true);

													setTimeout(() => {
														window.location.href = "/";
													}, 1500);
												}}
												className='text-danger'
												disabled={pressed}
											>
												<i className='fas fa-sign-out-alt'>&nbsp;</i>{" "}
												Déconnexion
											</MenuItem>
										</Menu>
									)}
									{window.innerWidth <= 991 && (
										<Menu
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											transformOrigin={{ horizontal: "right", vertical: "top" }}
											anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
										>
											<TextField
												id='search-input'
												label='Rechercher sur dealo'
												type='search'
												value={inputValue}
												onChange={(ev) => setInputValue(ev.target.value)}
												variant='outlined'
												onKeyPress={(ev) => {
													if (ev.key === "Enter") {
														window.location.href = `/filter?query=${ev.target.value}`;
														Cookies.set("query", ev.target.value);
														ev.preventDefault();
													}
												}}
												style={{ position: "relative" }}
											/>
										</Menu>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
			{/* MENU */}

			{/* MOBILE MENU */}

			<div>
				<div // offcanvas menu mobile
					className='offcanvas offcanvas-start'
					tabIndex='-1'
					id='mobileoffcanvas'
					aria-labelledby='mobileoffcanvasLabel'
				>
					<div className='offcanvas-header'>
						<h5 className='offcanvas-title' id='mobileoffcanvasLabel'>
							<img
								src={logo}
								alt='Dealo'
								height='40'
								style={{ cursor: "pointer" }}
								onClick={() => {
									window.location.href = "/";
								}}
							/>
						</h5>
						<button
							type='button'
							className='btn-close text-reset'
							data-bs-dismiss='offcanvas'
							aria-label='Close'
						></button>
					</div>
					<div className='offcanvas-body' style={{ marginRight: "10px" }}>
						<div className='menu-title'>Menu</div>
						<ul className='row d-flex gap-4'>
							{/* USER */}
							{/* home button that only appear when route is not home  */}
							{!["/", "/home"].includes(window.location.pathname) && (
								<li className='row gap-2'>
									<button
										className='display-5 btn btn-primary btn-lg'
										onClick={() => {
											window.location.href = "/";
										}}
									>
										<i className='fas fa-home'></i>{" "}
										<span className='t-dashboard'>Accueil</span>
									</button>
									<hr className='mt-2' />
								</li>
							)}
							{!id_user ? ( // Si l'utilisateur n'est pas connecté
								<li className='row gap-2'>
									<button
										className='display-5 btn btn-success btn-btm-warning btn-lg'
										onClick={() => {
											window.location.href = "/signup";
										}}
									>
										<i className='fas fa-user-plus '></i>{" "}
										<span className='t-dashboard'>Inscription</span>
									</button>
									<button
										className='display-5 btn btn-warning btn-btm-warning btn-lg'
										onClick={() => {
											window.location.href = "/login";
										}}
									>
										<i className='fas fa-sign-in-alt'></i>{" "}
										<span className='t-dashboard'>Connexion</span>
									</button>
									<hr className='mt-2' />
								</li>
							) : (
								// Si l'utilisateur est connecté
								<li className='row gap-2'>
									{/* profil */}
									<button
										className='display-5 btn btn-light btn-lg'
										onClick={() => {
											window.location.href = "/user/profile";
										}}
									>
										<span className='d-flex align-items-center justify-content-center '>
											<StyledBadge
												overlap='circular'
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												variant='dot'
											>
												<Avatar
													className='border'
													alt=""
													style={{
														width: "30px",
														height: "30px",
													}}
													src={profilePicture}
												/>
											</StyledBadge>
											<b className='mx-2' style={{ fontWeight: 600 }}>
												{username}
											</b>
										</span>
									</button>
									{/* post */}
									<button
										className='display-5 btn btn-warning btn-lg'
										onClick={() => {
											window.location.href = "/post/add";
										}}
									>
										<i className='fas fa-plus-square'></i>{" "}
										<span className='t-dashboard'>Poster une annonce</span>
									</button>
									{/* favorite */}
									<button
										className='display-5 btn btn-success btn-lg'
										onClick={() => {
											window.location.href = "/post/favorite";
										}}
									>
										<i className='fas fa-heart'></i>{" "}
										<span className='t-dashboard'>Annonces Favorites</span>
									</button>
									{/* deconnexion */}
									<button
										className='display-5 btn btn-danger btn-lg'
										onClick={() => {
											localStorage.removeItem("jwt");
											enqueueSnackbar("Déconnexion réussie", {
												variant: "success",
											});
											setPressed(true);

											setTimeout(() => {
												window.location.href = "/";
											}, 1500);
										}}
										disabled={pressed}
									>
										<i className='fas fa-sign-out-alt'></i>{" "}
										<span className='t-dashboard'>Déconnexion</span>
									</button>
									<hr className='mt-2' />
								</li>
							)}

							{/* OTHER MOBILE MENU ITEMS */}
							<li className='row gap-2' style={{ marginTop: "-20px" }}>
								<button
									className='display-5 btn btn-dark btn-lg'
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#categorie'
									aria-expanded='false'
									aria-controls='categorie'
								>
									<i className='fas fa-star'></i> Top catégories
									<i className='fas fa-chevron-down ms-2'></i>
								</button>
								<div className='collapse' id='categorie'>
									<ul className='list-group'>
										<li className='list-group-item'>
											<a href='/filter?category=1' className='text-reset'>
												<i className='fas fa-building me-3 fs-6'></i> Immobilier
											</a>
										</li>
										<li className='list-group-item'>
											<a
												href='/filter?category=5&subcategory=21'
												className='text-reset'
											>
												<i className='fas fa-mobile-alt fa-lg me-3 fs-6'></i>{" "}
												Smartphones
											</a>
										</li>
										<li className='list-group-item'>
											<a
												href='/filter?category=5&subcategory=23'
												className='text-reset'
											>
												<i className='fas fa-laptop fa-sm me-3 fs-6'></i>
												Ordinateurs
											</a>
										</li>
										<li className='list-group-item'>
											<a
												href='/filter?category=2&subcategory=6'
												className='text-reset'
											>
												<i className='fas fa-car me-3 fs-6'></i>Voitures
											</a>
										</li>
									</ul>
								</div>
							</li>

							<li className='row gap-2'>
								<button
									className='display-5 btn btn-secondary btn-lg'
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#conseils'
									aria-expanded='false'
									aria-controls='conseils'
								>
									<i className='fas fa-lightbulb'></i> &nbsp;Les Conseils
									<i className='fas fa-chevron-down ms-2'></i>
								</button>
								<div className='collapse' id='conseils'>
									<ul className='list-group'>
										<li className='list-group-item'>
											<a href='/sell' className='text-reset'>
												<i className='fas fa-hand-holding-usd me-3 fs-6'></i>{" "}
												Vendre
											</a>
										</li>
										<li className='list-group-item'>
											<a href='/buy' className='text-reset'>
												<i className='fas fa-shopping-cart me-3 fs-6'></i>{" "}
												Acheter
											</a>
										</li>
										<li className='list-group-item'>
											<a href='/security' className='text-reset'>
												<i className='fas fa-shield-alt me-3 fs-6'></i> Sécurité
											</a>
										</li>
										{/* <li className='list-group-item'>
											<a href='/boost' className='text-reset'>
												<i className='fas fa-rocket me-3 fs-6'></i> Boost annonce
											</a>
										</li> */}
									</ul>
								</div>
							</li>
							<li className='row gap-2'>
								<button
									className='display-5 btn btn-info btn-lg'
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#aide'
									aria-expanded='false'
									aria-controls='aide'
								>
									<i className='fas fa-life-ring'></i> Centre d'aide
									<i className='fas fa-chevron-down ms-2'></i>
								</button>
								<div className='collapse' id='aide'>
									<ul className='list-group'>
										<li className='list-group-item'>
											<a href='/faq' className='text-reset'>
												<i className='fas fa-question-circle me-3 fs-6'></i>FAQ
											</a>
										</li>
										<li className='list-group-item'>
											<a href='/rules' className='text-reset'>
												<i className='fas fa-gavel me-3 fs-6'></i>Réglement
												général
											</a>
										</li>
										<li className='list-group-item'>
											<a href='/terms' className='text-reset'>
												<i className='fas fa-file-contract me-3 fs-6'></i>{" "}
												Conditions d'utilisation
											</a>
										</li>
										<li className='list-group-item'>
											<a href='/privacy' className='text-reset'>
												<i className='fas fa-user-secret me-3 fs-6'></i>{" "}
												Politique de confidentialité
											</a>
										</li>
									</ul>
								</div>
							</li>

							<li className='row gap-2'>
								<button
									className='display-5 btn btn-light btn-lg'
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#contacter'
									aria-expanded='false'
									aria-controls='contacter'
								>
									<i className='fas fa-headset'></i> Assistance
									<i className='fas fa-chevron-down ms-2'></i>
								</button>
								<div className='collapse' id='contacter'>
									<ul className='list-group'>
										<li className='list-group-item'>
											<a href='/contact' className='text-reset'>
												<i className='fas fa-envelope me-3 fs-6'></i>Nous
												contactez ?
											</a>
										</li>
										{/* <li className='list-group-item'>
											<a href='#!' className='text-reset'>
												<i className='fas fa-phone-alt me-3'></i>06 77 06 03 67
											</a>
										</li> */}
									</ul>
								</div>
							</li>

							{isPWAInstalled &&
								isPWAInstalled === "false" &&
								!isPWA &&
								"serviceWorker" in navigator && (
									<li className='row gap-2'>
										<button
											onClick={handleInstallClick}
											className='display-5 btn btn-primary btn-lg d-flex align-items-center justify-content-center '
										>
											<InstallMobileIcon
												className='me-2 bx bx-flashing'
												sx={{ fontSize: "18px" }}
											/>
											Installer Dealo
										</button>
									</li>
								)}

							{/* AFFICHER LE RESTE DU MENU ICI  */}
						</ul>
					</div>
					<div
						className='text-center'
						style={{ position: "absolute", bottom: "0", width: "100%" }}
					>
						© {new Date().getFullYear()} Dealo - tout droit réservé
					</div>
				</div>
			</div>
			<div
				className='app-flex categories-row'
				style={{
					width: "90%",
					overflow: "auto",
					justifyContent: matches ? "flex-start" : "center",
					margin: "auto",
					maxWidth: "90%",
				}}
			>
				{/* {["/",].includes(window.location.pathname) ? ( */}
				<Subheader loader={loader} category={category} />
				{/* ) : null} */}
			</div>
		</header>
	);
}
