import React, { useState, useEffect, useCallback } from "react";
import { Grid, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { motion } from "framer-motion";
import logo from "../dealo/dealo-logo.png";
import hotPost from "../../components/images/hot-post-badge.gif";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/StarBorder";
import GradeIcon from "@mui/icons-material/Grade";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BrushIcon from "@mui/icons-material/Brush";
import { Button, Modal, Form } from "react-bootstrap";
import { useMediaQuery } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { jwtDecode } from "jwt-decode";
import { AdsArea } from "../../components";
import { useSnackbar } from "notistack";

import "./fancyCards.css";

const storedLayout = localStorage.getItem("layout")
	? jwtDecode(localStorage.getItem("layout")).layout
	: "PostCard";

const PostType = ({
	data,
	type = storedLayout,
	info,
	isCarousel,
	isBoosted = false,
	isUser,
}) => {
	// start
	const iphone = useMediaQuery("(max-width:340px)");
	const matches = useMediaQuery("(max-width:362px)");
	type = matches ? "PostCard" : type;

	const mobile = useMediaQuery("(max-width:992px)");
	const [isHovered, setIsHovered] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	useEffect(() => {
		setIsOpen(mobile || isHovered);
	}, [mobile, isHovered]);

	const { enqueueSnackbar } = useSnackbar(); // Snackbar notification

	// deletion logic
	const [uuid, setUuid] = useState(null);
	const [selectedPostId, setSelectedPostId] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showSurveyModal, setShowSurveyModal] = useState(false);
	const [selectedAnswer, setSelectedAnswer] = useState(null);
	const [isDeleting, setIsDeleting] = useState(false);

	// Get the user ID from the JWT
	const [id_user_jwt, setIdUserJwt] = useState(null);

	// Get the user ID from the JWT
	useEffect(() => {
		const jwtData = localStorage.getItem("jwt");
		if (jwtData) {
			const decodedJWT = jwtDecode(jwtData);
			setIdUserJwt(decodedJWT.id_user);
		}
	}, []);

	// Favorite logic
	const [favoriteIds, setFavoriteIds] = useState([]); // array to hold ids of favorited items

	// Iterate through each post
	let returnedFavArray = [];
	const pushFavoriteIds = async (posts, id_user) => {
		for (let i = 0; i < posts.length; i++) {
			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/isfavorite/${posts[i].id_post}/${id_user}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const isFavorited = await response.json(); // This returns a boolean value indicating if the post is favorited or not

			if (isFavorited.isFavorite == true) {
				returnedFavArray.push(posts[i].id_post);
			}
		}
		setFavoriteIds(returnedFavArray);
	};

	// Function to handle favoriting and unfavoriting posts
	const handleFavoriteToggle = useCallback(async (isUser, postId, event) => {
		try {
			// Check if the post is already favorited
			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/isfavorite/${postId}/${isUser}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const data = await response.json();

			const isFavorited = data.isFavorite;

			if (isFavorited) {
				// If the post is favorited, unfavorite it
				const response = await fetch(
					`${window.config.REACT_APP_BACKEND_URL}/api/delete/favorite`,
					{
						method: "DELETE",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							id_user: isUser,
							id_post: postId,
						}),
					}
				);

				if (response.ok) {
					event.target.style.background = "unset";
					enqueueSnackbar("Favoris supprimé avec succès !", {
						variant: "error",
					});
				} else {
					throw new Error("Failed to remove post from favorites");
				}
			} else {
				// If the post is not favorited, favorite it
				const response = await fetch(
					`${window.config.REACT_APP_BACKEND_URL}/api/add/favorite`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							id_user: isUser,
							id_post: postId,
						}),
					}
				);

				if (response.ok) {
					event.target.style.background = "#f99d1c";
					enqueueSnackbar("Ajouté aux favoris avec succès !", {
						variant: "success",
					});
				} else {
					throw new Error("Failed to add post to favorites");
				}
			}
		} catch (error) {
			console.error("Error:", error);
		}
		// refresh the page after 1.2 sec only if the route is /post/favorite
		if (window.location.pathname === "/post/favorite") {
			setTimeout(() => {
				window.location.reload();
			}, 1200);
		}
	}, []);

	// Push the favorite IDs to the array
	useEffect(() => {
		id_user_jwt && pushFavoriteIds(data, id_user_jwt);
	}, [id_user_jwt]);

	const handleShowDeleteModal = (postId, uuid) => {
		setSelectedPostId(postId); // Set the ID of the post to be deleted
		setUuid(uuid);
		setShowDeleteModal(true); // Show the delete modal
	};

	const handleDelete = async () => {
		setIsDeleting(true);

		try {
			// Delete the post with the selected post ID
			await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/delete/post/${selectedPostId}`,
				{
					method: "DELETE",
				}
			);

			// Show a notification
			enqueueSnackbar("Annonce supprimée !", {
				variant: "success",
			});

			// Send the survey answer to the API
			const surveyAnswer = {
				id_user: uuid, // Use the user ID
				id_post: selectedPostId, // Use the selected post ID
				isSold: selectedAnswer === "Dealo" ? true : false,
				source: selectedAnswer,
			};

			const response = await fetch(
				`${window.config.REACT_APP_BACKEND_URL}/api/survey`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(surveyAnswer),
				}
			);

			const data = await response.json();
			// console.log(data);

			// Redirect to the homepage after 1.2 seconds
			setTimeout(() => {
				window.location.reload();
			}, 1200);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	// État pour suivre le nombre total d'éléments affichés
	const [visibleItems, setVisibleItems] = useState(20);
	const hasNextPage = data.length > visibleItems;

	// Fonction pour gérer le scroll
	// const handleScroll = () => {
	// 	const scrollPosition = window.innerHeight + window.scrollY;
	// 	const totalHeight = document.documentElement.scrollHeight;
	// 	const triggerOffset = totalHeight * 0.9; // 90% de la hauteur de la page

	// 	if (scrollPosition >= triggerOffset) {
	// 		// Si l'utilisateur est à la distance spécifiée du bas de la page, augmentez le nombre d'éléments visibles
	// 		setVisibleItems((prev) => prev + 20);
	// 	}
	// };

	// // Intersection Observer pour charger plus d'éléments lorsque l'utilisateur atteint la fin de la page
	// useEffect(() => {
	// 	const observer = new IntersectionObserver(
	// 		(entries) => {
	// 			entries.forEach((entry) => {
	// 				if (entry.isIntersecting) {
	// 					// Si l'utilisateur atteint la fin de la page, augmentez le nombre d'éléments visibles
	// 					setVisibleItems((prev) => prev + 20);
	// 					observer.unobserve(entry.target);
	// 				}
	// 			});
	// 		},
	// 		{
	// 			root: null,
	// 			rootMargin: "0px",
	// 			threshold: 0.1, // Déclencher lorsque 10% de l'élément est visible
	// 		}
	// 	);

	// 	// Observer le dernier élément de la liste visible
	// 	if (type === "PostCard" && data.length > 0) {
	// 		const lastItem = document.getElementById(`item-${visibleItems - 1}`);
	// 		if (lastItem) {
	// 			observer.observe(lastItem);
	// 		}
	// 	}

	// 	if (type === "PostList" && data.length > 0) {
	// 		const lastItem = document.getElementById(`item-${visibleItems - 1}`);
	// 		if (lastItem) {
	// 			observer.observe(lastItem);
	// 		}
	// 	}

	// 	// Ajoutez un écouteur d'événements de défilement
	// 	window.addEventListener("scroll", handleScroll);

	// 	// Nettoyez l'écouteur d'événements lors du démontage
	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 		observer.disconnect();
	// 	};
	// }, [visibleItems]); // Observer les changements dans le nombre d'éléments visibles

	return (
		<>
			<div // PostCard
				style={{
					display: "flex",
					justifyContent: mobile ? "center" : "flex-start",
					// : window.location.pathname.startsWith("/post/favorite") ||
					//   window.location.pathname.startsWith("/user/profile")
					// ? "center"
					flexWrap: isCarousel ? "nowrap" : "wrap",
				}}
			>
				{type === "PostCard" &&
					data.slice(0, visibleItems).map((item, index) => {
						// Afficher seulement les 20 premiers éléments ou moins si visibleItems est inférieur à 20
						const infoItem = info[index];
						const isOwner = (id_user) => isUser === item.id_user;

						return (
							// <React.Fragment>
							// 	{(index + 1) % 10 === 0 &&
							// 		window.location.pathname === "/filter" && (
							// 			<div className='mb-2'>
							// 				<AdsArea areaWidth={"120%"} areaHeight={"31vh"} />
							// 			</div>
							// 		)}
							<motion.div
								className='m-1'
								variants={
									!(
										window.location.pathname.startsWith("/user/profile") ||
										window.location.pathname.startsWith("/post/favorite")
									)
										? {
												initial: {
													opacity: 0,
													scale: 0.9,
												},
												in: {
													opacity: 1,
													scale: 1,
													transition: { duration: 0.5, delay: 0.2 },
												},
												out: {
													opacity: 0,
													scale: 0.8,
													transition: { duration: 0.3 },
												},
										  }
										: {}
								}
								animate={
									!(
										window.location.pathname.startsWith("/user/profile") ||
										window.location.pathname.startsWith("/post/favorite")
									)
										? "in"
										: ""
								}
								initial={
									!(
										window.location.pathname.startsWith("/user/profile") ||
										window.location.pathname.startsWith("/post/favorite")
									)
										? "initial"
										: ""
								}
								key={index}
							>
								<Card
									className={
										Boolean(item.isBoosted)
											? "fancyCard shadow-sm"
											: "shadow-sm"
									}
									sx={{
										position: "relative",
										width: iphone ? "115px" : matches ? "125px" : "151px",
										minWidth: iphone ? "115px" : matches ? "125px" : "151px",
										maxWidth: matches ? "146px" : "160px",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										backgroundColor: Boolean(item.isBoosted)
											? "#efe1ac"
											: "default",
										cursor: "pointer",
										borderRadius: "15px",
									}}
								>
									<CardContent
										sx={{
											padding: 0.1,
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<Box
											component='b'
											className='d-flex align-items-center'
											sx={{
												fontSize: { xs: 9.5, sm: 10 },
												wordWrap: "break-word",
												overflowWrap: "break-word",
												hyphens: "auto",
											}}
											onClick={(event) => {
												event.preventDefault();
												event.stopPropagation();
												window.location.href = `/user/profile/userid/${infoItem.id_user}`;
											}}
										>
											{infoItem.ratings > 0 && (
												<Box
													component='div'
													sx={{
														fontSize: { xs: 6, sm: 7 },
														color: "#61c087",
														marginRight: "1px",
													}}
												>
													{infoItem.ratings}
													<GradeIcon
														sx={{
															fontSize: { xs: 6, sm: 7 },
															marginBottom: "2px",
														}}
													/>
												</Box>
											)}
											<Avatar
												alt=''
												src={
													infoItem?.profilePicture
														? infoItem.profilePicture
														: ""
												}
												sx={{
													width: { xs: 17, sm: 22 },
													height: { xs: 17, sm: 22 },
													margin: 0.2,
												}}
											/>
											&nbsp;
											<Typography
												sx={{
													fontSize: { xs: 8, sm: 11 },
													fontWeight: 1000,
												}}
											>
												{infoItem?.username
													? infoItem.username.length > 8
														? infoItem.username.slice(0, 8).split(" ")[0] + ".."
														: infoItem.username
													: "user"}
											</Typography>
											&nbsp;
											{item.accountType === "admin" ||
											item.accountType === "pro" ? (
												<Typography
													component='span'
													sx={{
														fontSize: { xs: "5px", sm: "5px" },
														backgroundImage:
															"linear-gradient(45deg, #e8e22e, #f5b223)",
														padding: "2px 4px",
														fontWeight: "bold",
														color: "white",
														borderRadius: "5px",
														textAlign: "center",
														border: "1px solid",
													}}
												>
													PRO
												</Typography>
											) : null}
											&nbsp; &nbsp;
										</Box>

										{id_user_jwt && id_user_jwt !== item.id_user ? (
											<StarIcon
												sx={{
													fontSize: { xs: 14, sm: 20 },
													boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
													borderRadius: 1,
													cursor: "pointer",
													background: favoriteIds.includes(item.id_post)
														? "#f99d1c"
														: "default",
													"&:hover": {
														background: "#f99d1c",
													},
												}}
												onClick={(event) => {
													event.preventDefault();
													event.stopPropagation();
													handleFavoriteToggle(
														id_user_jwt,
														item.id_post,
														event
													);
												}}
											/>
										) : null}
									</CardContent>
									<Box
										onClick={() =>
											(window.location.href = `/post/details/${item.id_post}`)
										}
										sx={{
											overflow: "hidden",
											position: "relative",
											pb: "105%",
										}}
									>
										<CardMedia
											component='img'
											image={item.imageUrl[0]}
											alt='Image'
											sx={{
												position: "absolute",
												top: 0,
												left: 0,
												height: "100%",
												width: "100%",
												objectFit: "cover",
												borderRadius: "7px",
											}}
										/>
									</Box>
									<a
										href={`/post/details/${item.id_post}`}
										style={{ color: "inherit", textDecoration: "none" }}
									>
										<CardContent
											sx={{
												display: "flex",
												flexDirection: { xs: "column" },
												justifyContent: "space-between",
												alignItems: "center",
												marginTop: "-10px",
												marginBottom: "-20px",
											}}
										>
											<Typography
												variant='p'
												component='div'
												sx={{
													fontWeight: "bold",
													fontSize: { xs: 10, sm: 12 },
													wordWrap: "break-word",
													overflowWrap: "break-word",
													hyphens: "auto",
												}}
											>
												{item.title.length > 12
													? item.title.slice(0, 12) + "..."
													: item.title}
											</Typography>
											<Typography
												variant='body2'
												color='text.secondary'
												sx={{
													fontSize: { xs: 10, sm: 12 },
													fontWeight: "1000",
													color: "#61c087",
													wordWrap: "break-word",
													overflowWrap: "break-word",
													hyphens: "auto",
												}}
											>
												{item.price.length > 14
													? item.price === "0 FCFA"
														? "GRATUIT"
														: item.price.slice(0, 14) + "..."
													: item.price === "0 FCFA"
													? "GRATUIT"
													: item.price}
											</Typography>
											<Typography
												variant='body2'
												color='text.secondary'
												sx={{
													fontSize: { xs: 9, sm: 11 },
													fontWeight: "1000",
													wordWrap: "break-word",
													overflowWrap: "break-word",
													hyphens: "auto",
												}}
											>
												{new Date(item.date).toLocaleDateString("fr-FR", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
												}) +
													" à " +
													new Date(item.date).toLocaleTimeString("fr-FR", {
														hour: "2-digit",
														minute: "2-digit",
													})}
											</Typography>
											<Typography
												variant='body2'
												color='text.secondary'
												sx={{
													fontSize: { xs: 12, sm: 13 },
													fontWeight: "1000",
												}}
											>
												{item.department}
											</Typography>
										</CardContent>
									</a>
									{
										/*Boolean(item.isBoosted) &&*/ item.views > 200 ? (
											<Typography component='div' className='hot-post-gif'>
												<Box component='img' src={hotPost} alt='hot' />
											</Typography>
										) : null
									}

									{isOwner(item.id_user) && (
										<div>
											<div>
												<Tooltip title='Supprimer'>
													<DeleteOutlineIcon
														sx={{
															color: "red",
															cursor: "pointer",
															margin: "1px",
															fontSize: 20,
															boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
															borderRadius: 1,
															cursor: "pointer",
															"&:hover": {
																color: "#61c087",
															},
														}}
														onClick={(event) => {
															event.preventDefault();
															event.stopPropagation();
															handleShowDeleteModal(item.id_post, item.id_user);
															// console.log("Delete post:" + item.id_post);
														}}
													/>
												</Tooltip>
												<Tooltip title='Modifier'>
													<BrushIcon
														sx={{
															color: "#f99d1c",
															cursor: "pointer",
															marginLeft: "3px",
															fontSize: 20,
															boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
															borderRadius: 1,
															cursor: "pointer",
															"&:hover": {
																color: "#61c087",
															},
														}}
														onClick={(event) => {
															event.preventDefault();
															event.stopPropagation();
															window.location.href = `/post/edit/${item.id_post}`;
															// console.log("Edit post");
														}}
													/>
												</Tooltip>
												<VisibilityIcon
													sx={{
														color: "dark",
														cursor: "pointer",
														fontSize: { xs: 11, sm: 12 },
														marginLeft: "5px",
													}}
												/>
												<Typography
													component='span'
													sx={{ fontSize: { xs: 9, sm: 10 } }}
												>
													{" " + item.views + " vues"}
												</Typography>
											</div>
										</div>
									)}
								</Card>
							</motion.div>
							// </React.Fragment>
						);
					})}
			</div>

			<div // PostList
			>
				{type === "PostList" && (
					// {/* DO NOT MOVE THIS GRID */}
					<Grid
						container
						spacing={2}
						justifyContent='center'
						style={{
							flexWrap: isCarousel ? "nowrap" : "wrap",
							height: matches && isCarousel ? "200px" : "auto",
							alignItems: "center",
						}}
					>
						{/* DO NOT MOVE THIS GRID */}
						{data.slice(0, visibleItems).map((item, index) => {
							// Afficher seulement les 20 premiers éléments ou moins si visibleItems est inférieur à 20
							const infoItem = info[index];
							const isOwner = (id_user) => isUser === item.id_user;
							return (
								<Grid
									item
									xs={11.95}
									sm={11.95}
									md={11.95}
									lg={11.95}
									key={index}
								>
									{/* <React.Fragment>
										{(index + 1) % 10 === 0 &&
											window.location.pathname === "/filter" && (
												<div className='mb-3'>
													<AdsArea areaWidth={"100%"} areaHeight={"30vh"} />
												</div>
											)} */}
									<motion.div
										variants={
											!(
												window.location.pathname.startsWith("/user/profile") ||
												window.location.pathname.startsWith("/post/favorite")
											)
												? {
														initial: {
															opacity: 0,
															scale: 0.9,
														},
														in: {
															opacity: 1,
															scale: 1,
															transition: { duration: 0.5, delay: 0.2 },
														},
														out: {
															opacity: 0,
															scale: 0.8,
															transition: { duration: 0.3 },
														},
												  }
												: {}
										}
										animate={
											!(
												window.location.pathname.startsWith("/user/profile") ||
												window.location.pathname.startsWith("/post/favorite")
											)
												? "in"
												: ""
										}
										initial={
											!(
												window.location.pathname.startsWith("/user/profile") ||
												window.location.pathname.startsWith("/post/favorite")
											)
												? "initial"
												: ""
										}
									>
										<a
											href={`/post/details/${item.id_post}`}
											style={{
												color: "inherit",
												textDecoration: "none",
												WebkitAppearance: "none",
											}}
										>
											<Card
												className={
													Boolean(item.isBoosted)
														? "fancyCard shadow-sm"
														: "shadow-sm"
												}
												sx={{
													position: "relative",
													minHeight: matches ? "160px" : "180px",
													maxHeight: matches ? "160px" : "180px",
													height: matches ? "160px" : "180px",
													position: "relative",
													minWidth: matches && isCarousel ? "250px" : "100%",
													maxWidth: matches && isCarousel ? "250px" : "100%",
													backgroundColor: Boolean(item.isBoosted)
														? "#efe1ac"
														: "default",
													borderRadius: "10px",
												}}
											>
												{item.accountType === "admin" ||
												item.accountType === "pro" ? (
													<Typography
														component='span'
														sx={{
															fontSize: { xs: "10px", sm: "12px" },
															backgroundImage:
																"linear-gradient(45deg, #e8e22e, #f5b223)",
															padding: "2px 4px",
															fontWeight: "bold",
															color: "white",
															borderRadius: "5px",
															position: "absolute",
															top: "5px",
															right: "5px",
															zIndex: 1,
															border: "1px solid",
														}}
													>
														PRO
													</Typography>
												) : null}

												{id_user_jwt && id_user_jwt !== item.id_user ? (
													<StarIcon
														sx={{
															fontSize: { xs: 15, sm: 20 },
															position: "absolute",
															bottom: "10px",
															right: "10px",
															zIndex: 1,
															boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
															borderRadius: 1,
															cursor: "pointer",
															background: favoriteIds.includes(item.id_post)
																? "#f99d1c"
																: "default",

															"&:hover": {
																background: "#f99d1c",
															},
														}}
														onClick={(event) => {
															event.preventDefault();
															event.stopPropagation();
															handleFavoriteToggle(
																id_user_jwt,
																item.id_post,
																event
															);
														}}
													/>
												) : null}

												{
													/*Boolean(item.isBoosted) &&*/ item.views > 200 ? (
														<Typography
															component='div'
															className='hot-post-gif'
															sx={{
																position: "absolute",
																bottom:
																	id_user_jwt !== item.id_user
																		? "40px"
																		: "10px",
																right:
																	id_user_jwt !== item.id_user
																		? "10px"
																		: "10px",
															}}
														>
															<Box component='img' src={hotPost} alt='hot' />
														</Typography>
													) : null
												}
												{isOwner(item.id_user) && (
													<div>
														<div style={{ margin: "1px" }}>
															<Tooltip title='Modifier' placement='left'>
																<BrushIcon
																	sx={{
																		color: "#f99d1c",
																		cursor: "pointer",
																		position: "absolute",
																		bottom: "42px",
																		right: "10px",
																		fontSize: 20,
																		boxShadow:
																			"0px 0px 5px rgba(0, 0, 0, 0.25)",
																		borderRadius: 1,
																		"&:hover": {
																			color: "#61c087",
																		},
																	}}
																	onClick={(event) => {
																		event.preventDefault();
																		event.stopPropagation();
																		window.location.href = `/post/edit/${item.id_post}`;
																		// console.log("Edit post");
																	}}
																/>
															</Tooltip>
															<Tooltip title='Supprimer' placement='left'>
																<DeleteOutlineIcon
																	sx={{
																		color: "red",
																		cursor: "pointer",
																		position: "absolute",
																		bottom: "80px",
																		right: "10px",
																		fontSize: 20,
																		boxShadow:
																			"0px 0px 5px rgba(0, 0, 0, 0.25)",
																		borderRadius: 1,
																		"&:hover": {
																			color: "#61c087",
																		},
																	}}
																	onClick={(event) => {
																		event.preventDefault();
																		event.stopPropagation();
																		handleShowDeleteModal(
																			item.id_post,
																			item.id_user
																		);
																		// console.log("Delete post:" + item.id_post);
																	}}
																/>
															</Tooltip>
															<Tooltip
																title={item.views + " vues"}
																placement='left'
																open={isOpen}
																onMouseEnter={() => setIsHovered(true)}
																onMouseLeave={() => setIsHovered(false)}
																style={{ zIndex: 1 }} // Add this line
																PopperProps={{ style: { zIndex: 1 } }} // Add this line
															>
																<VisibilityIcon
																	sx={{
																		color: "dark",
																		cursor: "pointer",
																		position: "absolute",
																		bottom: "120px",
																		right: "10px",
																		fontSize: 20,
																		boxShadow:
																			"0px 0px 5px rgba(0, 0, 0, 0.25)",
																		borderRadius: 1,
																		zIndex: 1,
																	}}
																/>
															</Tooltip>
														</div>
													</div>
												)}
												<CardContent
													style={{
														display: "flex",
														justifyContent: "flex-start",
														paddingBottom: "0",
														padding: "0",
														gap: "10px",
														height: "100%",
														width: "100%",
													}}
												>
													<Box
														sx={{
															display: "flex",
															width: {
																xs: "40%",
																sm: "30%",
																md: "30%",
																lg: "20%",
																xl: "20%",
															},
															minHeight: "100%",
															overflow: "hidden",
														}}
													>
														<CardMedia
															component='img'
															image={item.imageUrl[0]}
															className='app-flex'
															sx={{
																objectFit: "cover",
																verticalAlign: "middle",
																maxHeight: "100%",
																height: "100%",
																maxWidth: "100%",
																borderRadius: 1,
															}}
															alt='Image'
														/>
													</Box>
													<div
														style={{
															width: "70%",
															minHeight: "100%",
															padding: "20px 0px 0px 5px",
														}}
													>
														<Typography
															variant='p'
															component='div'
															sx={{
																fontWeight: "bold",
																fontSize: { xs: 15, sm: 16 },
																wordWrap: "break-word",
																overflowWrap: "break-word",
																hyphens: "auto",
																maxWidth: "100%",
															}}
														>
															{item.title.length > 30
																? item.title.slice(0, 30) + " ..."
																: item.title}
														</Typography>

														<Box
															sx={{ display: "flex", alignItems: "center" }}
															onClick={(event) => {
																event.preventDefault();
																event.stopPropagation();
																window.location.href = `/user/profile/userid/${infoItem.id_user}`;
															}}
														>
															<Avatar
																alt=''
																src={
																	infoItem?.profilePicture
																		? infoItem.profilePicture
																		: ""
																}
																sx={{
																	width: { xs: 25, sm: 25 },
																	height: { xs: 25, sm: 25 },
																	margin: 0.1,
																	border: "1px solid",
																	marginRight: "5px",
																}}
															/>
															<Typography
																sx={{
																	fontSize: { xs: 13, sm: 15 },
																	wordWrap: "break-word",
																	overflowWrap: "break-word",
																	hyphens: "auto",
																	maxWidth: "100%",
																}}
															>
																{infoItem?.username
																	? infoItem.username.length > 9
																		? infoItem.username
																				.slice(0, 9)
																				.split(" ")[0]
																		: infoItem.username
																	: "user"}{" "}
															</Typography>
															{infoItem.ratings > 0 && (
																<Box
																	component='div'
																	sx={{
																		fontSize: { xs: 10, sm: 11 },
																		color: "#61c087",
																		marginRight: "2px",
																		marginBottom: "-2px",
																	}}
																>
																	&nbsp;
																	{infoItem.ratings}
																	<GradeIcon
																		sx={{
																			fontSize: { xs: 12, sm: 15 },
																			marginBottom: "4px",
																		}}
																	/>
																</Box>
															)}
														</Box>

														<Typography
															variant='body2'
															color='text.secondary'
															sx={{
																fontSize: { xs: 15, sm: 15 },
																fontWeight: "1000",
																color: "#61c087",
																wordWrap: "break-word",
																overflowWrap: "break-word",
																hyphens: "auto",
																maxWidth: "100%",
															}}
														>
															{item.price === "0 FCFA" ? "GRATUIT" : item.price}
														</Typography>
														<Typography
															variant='body2'
															color='text.secondary'
															sx={{
																fontSize: { xs: 15, sm: 15 },
																wordWrap: "break-word",
																overflowWrap: "break-word",
																hyphens: "auto",
																maxWidth: "100%",
															}}
														>
															{new Date(item.date).toLocaleDateString("fr-FR", {
																day: "2-digit",
																month: "2-digit",
																year: "numeric",
															}) +
																" à " +
																new Date(item.date).toLocaleTimeString(
																	"fr-FR",
																	{
																		hour: "2-digit",
																		minute: "2-digit",
																	}
																)}
														</Typography>
														<Typography
															variant='body2'
															color='text.secondary'
															sx={{
																fontSize: { xs: 15, sm: 15 },
																wordWrap: "break-word",
																overflowWrap: "break-word",
																hyphens: "auto",
																maxWidth: "100%",
															}}
														>
															{item.department}
														</Typography>
													</div>
												</CardContent>
											</Card>
										</a>
									</motion.div>
									{/* </React.Fragment> */}
								</Grid>
							);
						})}
					</Grid>
				)}
			</div>

			{/* Chargez plus d'annonces */}
			{hasNextPage && (
				<div style={{ margin: "auto", width: "fit-content" }}>
					<button
						className='app-flex mt-1'
						style={{
							display: "flex",
							padding: "5px 10px",
							borderRadius: "15px",
							backgroundColor: "white",
							boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
							fontWeight: "bold",
							gap: "5px",
							borderColor: "#60ba78",
							borderStyle: "solid",
							borderWidth: "1px", 
						}}
						onClick={() => setVisibleItems((prev) => prev + 20)}
					>
						<p style={{ marginBottom: "0" }}>Voir plus</p>{" "}
						<img src={logo} width={30} alt='img' />
					</button>
				</div>
			)}

			{/* delete modal  */}
			<div>
				{/* Modal for delete */}
				<Modal
					show={showDeleteModal}
					onHide={() => setShowDeleteModal(false)}
					centered
					style={{ fontSize: "1rem" }}
				>
					<Modal.Header>Confirmation de suppression</Modal.Header>
					<Modal.Body>
						Etes vous sûr de vouloir supprimer cette annonce ?
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant='secondary'
							onClick={() => setShowDeleteModal(false)}
						>
							Annuler
						</Button>
						<Button
							variant='danger'
							onClick={() => {
								setShowDeleteModal(false);
								setShowSurveyModal(true);
							}}
						>
							Supprimer
						</Button>
					</Modal.Footer>
				</Modal>

				{/* survey modal  */}
				<Modal
					show={showSurveyModal}
					onHide={() => setShowSurveyModal(false)}
					centered
					style={{ fontSize: "1rem" }}
				>
					<Modal.Header>
						<Modal.Title>Enquête - suppression</Modal.Title>
						<Button variant='close' onClick={() => setShowSurveyModal(false)} />
					</Modal.Header>
					<Modal.Body className='mx-auto'>
						{/* Survey questions */}
						<Form.Group>
							<Form.Check
								type='radio'
								label={
									<>
										<i className='fas fa-check-circle'></i> J'ai vendu mon
										article sur Dealo
									</>
								}
								value='Dealo'
								checked={selectedAnswer === "Dealo"}
								onChange={(e) => setSelectedAnswer(e.target.value)}
							/>
							<Form.Check
								type='radio'
								label={
									<>
										<i className='fas fa-times-circle'></i> J'ai vendu mon
										article sur un autre site
									</>
								}
								value='OtherSite'
								checked={selectedAnswer === "OtherSite"}
								onChange={(e) => setSelectedAnswer(e.target.value)}
							/>
							<Form.Check
								type='radio'
								label={
									<>
										<i className='fas fa-circle'></i> Je n'ai pas vendu mon
										article
									</>
								}
								value='NotSold'
								checked={selectedAnswer === "NotSold"}
								onChange={(e) => setSelectedAnswer(e.target.value)}
							/>
							<Form.Check
								type='radio'
								label={
									<>
										<i className='fas fa-minus-circle'></i> J'ai retiré mon
										article de la vente
									</>
								}
								value='Removed'
								checked={selectedAnswer === "Removed"}
								onChange={(e) => setSelectedAnswer(e.target.value)}
							/>
							<Form.Check
								type='radio'
								label={
									<>
										<i className='fas fa-question-circle'></i> Je préfère ne pas
										répondre
									</>
								}
								value='NotAnswered'
								checked={selectedAnswer === "NotAnswered"}
								onChange={(e) => setSelectedAnswer(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant='secondary'
							onClick={() => setShowSurveyModal(false)}
						>
							Annuler
						</Button>
						<Button
							variant='danger'
							onClick={handleDelete}
							disabled={isDeleting}
						>
							Confirmer
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</>
	);
};

export default PostType;
